import React from "react";
import "./../../css/General.css";
import "./../../css/TradeIHoreca.css";
import SliderServices from "./SliderServices";

const images = [
  "/TradeIhoreCa/01.jpg",
  "/TradeIhoreCa/02.jpg",
  "/TradeIhoreCa/03.jpg",
  "/TradeIhoreCa/04.jpg",
  "/TradeIhoreCa/05.jpg",
  "/TradeIhoreCa/06.jpg",
  "/TradeIhoreCa/07.jpg",
  "/TradeIhoreCa/08.jpg",
];

class TradeIHoreca extends React.Component {
  render() {
    return (
      <div className="page" id="trade-horeca">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold-horeca">TRADE I HORECA</span>
            <br />
            <span>PROMOCIJE</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
          <div className="page-text">
            Organizujemo sve vrste promotivnih aktivnosti kroz <br />
            <span className="red-bold-text" style={{ fontSize: "16px" }}>
              Retail
            </span>{" "}
            i{" "}
            <span className="red-bold-text" style={{ fontSize: "16px" }}>
              HoReCa kanal
            </span>
            .
          </div>
          <div className="page-text" style={{ paddingBottom: "100px" }}>
            Kroz direktan kontakt sa vašom ciljnom grupom, osiguravamo vam
            povećanje prodaje i predstavljanje vašeg brenda u skladu sa vašim
            ciljevima.
          </div>
          <div className="nothing-mobile"></div>
        </div>

        <div className="page-right">
          <div className="page-slide">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TradeIHoreca;
