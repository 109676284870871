import React from "react";
import "./../../css/General.css";
import SliderServices from "./SliderServices";

const images = [
  "/ImpresivnePostavke/impresivnePostavke4.jpg",
  "/ImpresivnePostavke/impresivnePostavke1.jpg",
  "/ImpresivnePostavke/impresivnePostavke2.jpg",
  "/ImpresivnePostavke/impresivnePostavke3.jpg",
];

class ImpresivnePostavke extends React.Component {
  render() {
    return (
      <div className="page" id="impresivne-postavke">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">IMPRESIVNE</span>
            <br />
            <span>POSTAVKE</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
          <div className="page-text">
            <span className="red-italic-text">
              „Prihvatite svoju različitost.“
            </span>
          </div>
          <div className="page-space"></div>

          <div className="page-text">
            Vreme je da privučete pažnju. Preko 90% ljudske komunikacije čini
            vizuelni sadržaj, zato je misija tima naših dizajnera, inženjera i
            produkcije da vaš brend kroz upečatljivu postavku i brending ispriča
            svoju priču.
          </div>
          <div className="page-text">
            Futuristička idejna rešenja naš produkcioni tim će sa lakoćom
            pretvoriti u stvarnost.
          </div>
        </div>

        <div className="page-right">
          <div className="page-slide">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ImpresivnePostavke;
