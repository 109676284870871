import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { __ } from "../utils/i18n";
import "./../css/Header.css";

class Header extends React.Component {
  componentDidMount() {
    let sr = document.getElementById("sr");
    let en = document.getElementById("en");

    if (localStorage.getItem("localeNew") === "en") {
      sr.style.color = "#810708";
      en.style.color = "#d0d0d0";
    } else {
      sr.style.color = "#d0d0d0";
      en.style.color = "#810708";
    }
  }

  changeToEng() {
    window.localStorage.setItem("localeNew", "en");
    window.location.reload();
  }

  changeToSrb() {
    window.localStorage.setItem("localeNew", "sr");
    window.location.reload();
  }

  render() {
    return (
      <div className="header">
        <div className="content">
          <div className="fa_bars">
            <label htmlFor="check">
              <input
                type="checkbox"
                id="check"
                defaultChecked={this.props.complete}
              />
              <FontAwesomeIcon
                icon={faBars}
                className="fa_bars_icon"
                id="fa_bars_icon"
              />
            </label>

            <div className="header_list">
              <ul>
                <li>
                  {" "}
                  <a href="/#o-nama"> O nama </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#usluge"> Usluge </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#storytelling"> Storytelling </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#info"> Info </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#kontakt"> Kontakt </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#postani-deo-tima"> Prijavi se </a>{" "}
                </li>
              </ul>
            </div>
          </div>

          <div className="languages">
            <button id="sr" onClick={this.changeToSrb}>
              sr
            </button>
            <button id="en" onClick={this.changeToEng}>
              en
            </button>
          </div>

          <div className="info">
            <p>{__("visit us")}</p>
            <div>
              <a
                href="https://sr-rs.facebook.com/Poliplan1to1/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/facebook.png" alt="facebook" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/poliplan1to1/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/instagram.png" alt="instagram" />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/company/poliplan-1to1/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/linkedin.png" alt="linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
