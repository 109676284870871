import React from "react";
import "./../css/Philips.css";

class Philips extends React.Component {
  render() {
    return (
      <div>
        <img className="philips-img" src="/philips.jpg" alt="prijaviSe" />
        <div className="philips-div">
          <a
            className="philips-pdf"
            href="PravilaNagradnogKonkursaNiKarticuNePeglamBrže.pdf"
            target="_blank"
          >
            {" "}
            PREUZMITE PRAVILA NAGRADNOG KONKURSA{" "}
          </a>
          <p>Pobednik nagradne igre je Lada Filipović (@lada.filipovic)</p>
        </div>
        <img
          className="philips-img-mobile"
          src="/philipsMobile.jpg"
          alt="prijaviSe"
        />
        <div className="philips-div-mobile">
          <a
            className="philips-pdf-mobile"
            href="PravilaNagradnogKonkursaNiKarticuNePeglamBrže.pdf"
            target="_blank"
          >
            {" "}
            PREUZMITE PRAVILA NAGRADNOG KONKURSA{" "}
          </a>
          <p>Pobednik nagradne igre je Lada Filipović (@lada.filipovic)</p>
        </div>
      </div>
    );
  }
}

export default Philips;
