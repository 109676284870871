import React from "react";
import "./../../css/General.css";

class Strategija extends React.Component {
  render() {
    return (
      <div className="page" id="strategija">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">1TO1</span>
            <br />
            <span>STRATEGIJA</span>
          </div>
          <div className="page-right-mobile">
            <img
              src="/Strategija/Strategija.jpg"
              className="page-img"
              alt="allInOne"
            />
          </div>
          <div className="page-text">
            <span className="red-italic-text">
              „Ako ne razviješ sopstvenu strategiju postaješ deo tuđe.“
            </span>
          </div>
          <div className="page-space"></div>
          <div className="page-text">
            Detaljno analiziramo i upoznajemo vaš brend, njegovu poziciju na
            tržištu u odnosu na konkurenciju, i izrađujemo vašu marketing
            strategiju. Kroz definisanje ciljeva, akcija, rokova i budžeta
            dostavljamo vam sveobuhvatni akcioni plan za vaš brend i izveštavamo
            vas o rezultatima.
          </div>
        </div>

        <div className="page-right">
          <img
            src="/Strategija/Strategija.jpg"
            className="page-img"
            alt="strategija"
          />
        </div>
      </div>
    );
  }
}

export default Strategija;
