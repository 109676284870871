import React from "react";
import { __ } from "./../../utils/i18n";
import "./../../css/Screen1.css";
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

class Screen1 extends React.Component {
  constructor(init) {
    super(init);
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    const position = document.getElementById("o-nama");
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < position.offsetTop - 100;
      if (isTop !== true) {
        this.setState({ isVisible: true });
      }
    });
  }

  goToAboutUs(type) {
    let path = "/oNama";
    switch (type) {
      case "prijatelji":
        path += "/#nasiPrijatelji";
        break;
      case "porodica":
        path += "/#nasaPorodica";
        break;
      default:
        break;
    }

    window.location.href = path;
  }

  render() {
    return (
      <div>
        <div className="screen1" id="o-nama">
          <div
            style={{ width: "56%" }}
            className={this.state.isVisible ? "hr-line" : "notDisplay"}
          ></div>
          <div className="textONama">
            <span>O nama</span>
          </div>
          <div className="firstAnimation">
            <Animated
              animationIn="fadeInLeft"
              animationInDuration={1800}
              animationOutDuration={0}
              isVisible={this.state.isVisible}
            >
              <img className="imgHomepage" src="/vrata.jpg" alt="homepage" />
            </Animated>
          </div>
          <div className={this.state.isVisible ? "textScreen1" : "notDisplay"}>
            <div>
              <span className="hover-element">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-nasa-kuca"
                  className="caret-right"
                />
                <a href="/oNama">{__("NAŠA KUĆA")}</a>
                <span className="screen1-text-hover" id="nasa-kuca">
                  <span>
                    Temelje smo postavili 2006. Već 15 godina napredujemo,
                    rastemo i razvijamo se.{" "}
                    <span style={{ color: "#c30c0e", fontWeight: "bold" }}>
                      Dobro došli u Poliplan! <br />
                      <br />
                    </span>
                  </span>
                  <div className="button-screen1">
                    <button
                      className="button-vise"
                      onClick={this.goToAboutUs.bind(this, "kuca")}
                    >
                      VIŠE
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        className="caret-right-button"
                      />
                    </button>
                  </div>
                </span>
              </span>
            </div>
            <div>
              <span className="hover-element">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-nasa-porodica"
                  className="caret-right"
                />
                <a href="/oNama/#nasaPorodica">{__("NAŠA PORODICA")}</a>
                <span className="screen1-text-hover" id="nasa-porodica">
                  <span>
                    Stub svake kuće je porodica, a mi smo na našu izuzetno
                    ponosni. Upoznajte Poliplanovce – tim izuzetno kreativnih i
                    motivisanih profesionalaca!
                  </span>
                  <div className="button-screen1">
                    <button
                      className="button-vise"
                      onClick={this.goToAboutUs.bind(this, "porodica")}
                    >
                      VIŠE
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        className="caret-right-button"
                      />
                    </button>
                  </div>
                </span>
              </span>
            </div>
            <div>
              <span className="hover-element">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-nasi-prijatelji"
                  className="caret-right"
                />
                <a href="/oNama/#nasiPrijatelji">{__("NAŠI PRIJATELJI")}</a>
                <span className="screen1-text-hover" id="nasi-prijatelji">
                  <span>
                    Naše partnere smatramo našim prijateljima. Poverenje koje
                    gradimo i odnos koji imamo u poslu negujemo pažljivo.
                    Ponosni smo na krug naših prijatelja, i pružamo vam ruku da
                    postanete jedan od njih!
                  </span>
                  <div className="button-screen1">
                    <button
                      className="button-vise"
                      onClick={this.goToAboutUs.bind(this, "prijatelji")}
                    >
                      VIŠE
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        className="caret-right-button"
                      />
                    </button>
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Screen1;
