import "./App.css";
import Homepage from "./components/Homepage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import PoliplanInNumbers from "./components/PoliplanInNumbers";
import AllInOne from "./components/AllInOne";
import OurTeam from "./components/OurTeam";
import CountingPage from "./components/CountingPage";
import Intervju from "./components/blog/Intervju";
import NasNacin from "./components/NasNacin";
import NoveTehnologije from "./components/blog/NoveTehnologije";
import Blog from "./components/blog/Blog";
import Philips from "./components/Philips";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/oNama" component={AboutUs} />
        <Route path="/usluge" component={Services} />
        <Route path="/poliplanUBrojevima" component={PoliplanInNumbers} />
        <Route path="/allInOne" component={AllInOne} />
        <Route path="/nasTim" component={OurTeam} />
        <Route path="/nasNacin" component={NasNacin} />
        <Route path="/intervju" component={Intervju} />
        <Route path="/noveTehnologije" component={NoveTehnologije} />
        <Route path="/blog" component={Blog} />

        <Route path="/caseStudy" component={CountingPage} />
        <Route path="/nikarticunepeglambrze" component={Philips} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
