import React from "react";
import "./../css/Screen1.css";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import MiddlePage from "./MiddlePage";
import MiddlePage1 from "./MiddlePage1";
import "./../css/AboutUs.css";
import styled, { keyframes } from "styled-components";
import SliderServices from "./services/SliderServices";

const images = [
  "/slikePoliplan/Bojan-Donevski-General-Manager.jpg",
  "/slikePoliplan/Aleksandar-Racic-–-Executive-director.jpg",
  "/slikePoliplan/Martina-Stošić-Group-Account-Director.jpg",
  "/slikePoliplan/Slaven-Buha-–-Events-and-Digital-Communications-Director.jpg",
  "/slikePoliplan/Marta-Klar-–-Creative-Director.jpg",
  "/slikePoliplan/Miroslav-Orlović-–-Production-Director.jpg",
  "/slikePoliplan/Ružica-Visković-–-Account-Director.jpg",
  "/slikePoliplan/Marko-Purar-–-BTL-Manager.jpg",
  "/slikePoliplan/Nikola-Vitas-–-Senior-Concept-Designer.jpg",
  "/slikePoliplan/Jelena-Delić-–-Prepress-Designer.jpg",
  "/slikePoliplan/Marija-Đorđević-–-BTL-Executive.jpg",
  "/slikePoliplan/Maja-Ranković-–-BTL-Executive.jpg",
  "/slikePoliplan/Jasmina-Sekulović-–-Digital-Communications-Executive.jpg",
  "/slikePoliplan/Nevena-Tomić--Digital-Communications-Manager.jpg",
  "/slikePoliplan/Marijana-Stojanović-–-Senior-BTL-Manager.jpg",
  "/slikePoliplan/Nataša-Sajić----BTL-Assistant.jpg",
  "/slikePoliplan/Saša-Tepšić-–-BTL-Executive.jpg",
  "/slikePoliplan/Anja-Krsmanović-–-Events-and-Activations-Executive.jpg",
  "/slikePoliplan/Dajana-Mitić-–-Junior-Graphic-Designer.jpg",
  "/slikePoliplan/Miloš-Mihajlović---Senior-BTL-Executive.jpg",
  "/slikePoliplan/Marijana-Miletić-–-Senior-Graphic-Designer.jpg",
  "/slikePoliplan/Ljubomir-Stamenkovic-01.jpg",
  "/slikePoliplan/Milica-01.jpg",
  "/slikePoliplan/Tamara-Lazarević-–-Events-and-Activations-Executive.jpg",
  "/slikePoliplan/Danilo-Damjanović---Production-Manager.jpg",
  "/slikePoliplan/Dragan-Novaković-–-Production-Executive.jpg",
  "/slikePoliplan/Vladimir-Kasikovic-01.jpg",
];

const imagesPartneri = [
  "/Partneri/1.png",
  "/Partneri/2.png",
  "/Partneri/3.png",
  "/Partneri/4.png",
  "/Partneri/5.png",
  "/Partneri/6.png",
  "/Partneri/7.png",
  "/Partneri/8.png",
  "/Partneri/9.png",
  "/Partneri/10.png",
  "/Partneri/11.png",
  "/Partneri/12.png",
  "/Partneri/13.png",
  "/Partneri/14.png",
  "/Partneri/15.png",
  "/Partneri/16.png",
];

const show1 = keyframes`
  0% {background: url(/Partneri/1.png) no-repeat; background-size: 100%; opacity: 0;}
  3% {background: url(/Partneri/1.png) no-repeat; background-size: 100%; opacity: 1;}
  16% {background: url(/Partneri/1.png) no-repeat; background-size: 100%; opacity: 1;}
  19% {background: url(/Partneri/1.png) no-repeat; background-size: 100%; opacity: 0;}
  20% {background: url(/Partneri/5.png) no-repeat; background-size: 100%; opacity: 0;}
  23% {background: url(/Partneri/5.png) no-repeat; background-size: 100%; opacity: 1;}  
  36% {background: url(/Partneri/5.png) no-repeat; background-size: 100%; opacity: 1;}  
  39% {background: url(/Partneri/5.png) no-repeat; background-size: 100%; opacity: 0;}
  40% {background: url(/Partneri/9.png) no-repeat; background-size: 100%; opacity: 0;}
  43% {background: url(/Partneri/9.png) no-repeat; background-size: 100%; opacity: 1;}  
  56% {background: url(/Partneri/9.png) no-repeat; background-size: 100%; opacity: 1;} 
  59% {background: url(/Partneri/9.png) no-repeat; background-size: 100%; opacity: 0;}
  60% {background: url(/Partneri/13.png) no-repeat; background-size: 100%; opacity: 0;}
  63% {background: url(/Partneri/13.png) no-repeat; background-size: 100%; opacity: 1;}  
  76% {background: url(/Partneri/13.png) no-repeat; background-size: 100%; opacity: 1;}  
  79% {background: url(/Partneri/13.png) no-repeat; background-size: 100%; opacity: 0;}
  80% {background: url(/Partneri/17.png) no-repeat; background-size: 100%; opacity: 0;}
  83% {background: url(/Partneri/17.png) no-repeat; background-size: 100%; opacity: 1;}  
  96% {background: url(/Partneri/17.png) no-repeat; background-size: 100%; opacity: 1;}  
  100% {background: url(/Partneri/17.png) no-repeat; background-size: 100%; opacity: 0;}
`;

const show2 = keyframes`
  0% {background: url(/Partneri/2.png) no-repeat; background-size: 100%; opacity: 0;}
  3% {background: url(/Partneri/2.png) no-repeat; background-size: 100%; opacity: 1;}
  16% {background: url(/Partneri/2.png) no-repeat; background-size: 100%; opacity: 1;}
  19% {background: url(/Partneri/2.png) no-repeat; background-size: 100%; opacity: 0;}
  20% {background: url(/Partneri/6.png) no-repeat; background-size: 100%; opacity: 0;}
  23% {background: url(/Partneri/6.png) no-repeat; background-size: 100%; opacity: 1;}  
  36% {background: url(/Partneri/6.png) no-repeat; background-size: 100%; opacity: 1;}  
  39% {background: url(/Partneri/6.png) no-repeat; background-size: 100%; opacity: 0;}
  40% {background: url(/Partneri/10.png) no-repeat; background-size: 100%; opacity: 0;}
  43% {background: url(/Partneri/10.png) no-repeat; background-size: 100%; opacity: 1;}  
  56% {background: url(/Partneri/10.png) no-repeat; background-size: 100%; opacity: 1;} 
  59% {background: url(/Partneri/10.png) no-repeat; background-size: 100%; opacity: 0;}
  60% {background: url(/Partneri/14.png) no-repeat; background-size: 100%; opacity: 0;}
  63% {background: url(/Partneri/14.png) no-repeat; background-size: 100%; opacity: 1;}  
  76% {background: url(/Partneri/14.png) no-repeat; background-size: 100%; opacity: 1;}  
  79% {background: url(/Partneri/14.png) no-repeat; background-size: 100%; opacity: 0;}
  80% {background: url(/Partneri/18.png) no-repeat; background-size: 100%; opacity: 0;}
  83% {background: url(/Partneri/18.png) no-repeat; background-size: 100%; opacity: 1;}  
  96% {background: url(/Partneri/18.png) no-repeat; background-size: 100%; opacity: 1;}  
  100% {background: url(/Partneri/18.png) no-repeat; background-size: 100%; opacity: 0;}
`;

const show3 = keyframes`
  0% {background: url(/Partneri/3.png) no-repeat; background-size: 100%; opacity: 0;}
  3% {background: url(/Partneri/3.png) no-repeat; background-size: 100%; opacity: 1;}
  16% {background: url(/Partneri/3.png) no-repeat; background-size: 100%; opacity: 1;}
  19% {background: url(/Partneri/3.png) no-repeat; background-size: 100%; opacity: 0;}
  20% {background: url(/Partneri/7.png) no-repeat; background-size: 100%; opacity: 0;}
  23% {background: url(/Partneri/7.png) no-repeat; background-size: 100%; opacity: 1;}  
  36% {background: url(/Partneri/7.png) no-repeat; background-size: 100%; opacity: 1;}  
  39% {background: url(/Partneri/7.png) no-repeat; background-size: 100%; opacity: 0;}
  40% {background: url(/Partneri/11.png) no-repeat; background-size: 100%; opacity: 0;}
  43% {background: url(/Partneri/11.png) no-repeat; background-size: 100%; opacity: 1;}  
  56% {background: url(/Partneri/11.png) no-repeat; background-size: 100%; opacity: 1;} 
  59% {background: url(/Partneri/11.png) no-repeat; background-size: 100%; opacity: 0;}
  60% {background: url(/Partneri/15.png) no-repeat; background-size: 100%; opacity: 0;}
  63% {background: url(/Partneri/15.png) no-repeat; background-size: 100%; opacity: 1;}  
  76% {background: url(/Partneri/15.png) no-repeat; background-size: 100%; opacity: 1;}  
  79% {background: url(/Partneri/15.png) no-repeat; background-size: 100%; opacity: 0;}
  80% {background: url(/Partneri/11.png) no-repeat; background-size: 100%; opacity: 0;}
  83% {background: url(/Partneri/11.png) no-repeat; background-size: 100%; opacity: 1;}  
  96% {background: url(/Partneri/11.png) no-repeat; background-size: 100%; opacity: 1;}  
  100% {background: url(/Partneri/11.png) no-repeat; background-size: 100%; opacity: 0;}
`;

const show4 = keyframes`
  0% {background: url(/Partneri/4.png) no-repeat; background-size: 100%; opacity: 0;}
  3% {background: url(/Partneri/4.png) no-repeat; background-size: 100%; opacity: 1;}
  16% {background: url(/Partneri/4.png) no-repeat; background-size: 100%; opacity: 1;}
  19% {background: url(/Partneri/4.png) no-repeat; background-size: 100%; opacity: 0;}
  20% {background: url(/Partneri/8.png) no-repeat; background-size: 100%; opacity: 0;}
  23% {background: url(/Partneri/8.png) no-repeat; background-size: 100%; opacity: 1;}  
  36% {background: url(/Partneri/8.png) no-repeat; background-size: 100%; opacity: 1;}  
  39% {background: url(/Partneri/8.png) no-repeat; background-size: 100%; opacity: 0;}
  40% {background: url(/Partneri/12.png) no-repeat; background-size: 100%; opacity: 0;}
  43% {background: url(/Partneri/12.png) no-repeat; background-size: 100%; opacity: 1;}  
  56% {background: url(/Partneri/12.png) no-repeat; background-size: 100%; opacity: 1;} 
  59% {background: url(/Partneri/12.png) no-repeat; background-size: 100%; opacity: 0;}
  60% {background: url(/Partneri/16.png) no-repeat; background-size: 100%; opacity: 0;}
  63% {background: url(/Partneri/16.png) no-repeat; background-size: 100%; opacity: 1;}  
  76% {background: url(/Partneri/16.png) no-repeat; background-size: 100%; opacity: 1;}  
  79% {background: url(/Partneri/16.png) no-repeat; background-size: 100%; opacity: 0;}
  80% {background: url(/Partneri/12.png) no-repeat; background-size: 100%; opacity: 0;}
  83% {background: url(/Partneri/12.png) no-repeat; background-size: 100%; opacity: 1;}  
  96% {background: url(/Partneri/12.png) no-repeat; background-size: 100%; opacity: 1;}  
  100% {background: url(/Partneri/12.png) no-repeat; background-size: 100%; opacity: 0;}
`;

const Logo1 = styled.div`
  background: "url(/Partneri/1.png) no-repeat";
  backgroundsize: "100%";
  margin-left: 5%;
  margin-right: 5%;
  width: 40%;
  height: 200px;
  animation: ${show1} 40s linear 0s infinite;
`;

const Logo2 = styled.div`
  background: "url(/Partneri/2.png) no-repeat";
  backgroundsize: "100%";
  margin-left: 5%;
  margin-right: 5%;
  width: 40%;
  height: 200px;
  animation: ${show2} 40s linear 0s infinite;
`;

const Logo3 = styled.div`
  background: "url(/Partneri/3.png) no-repeat";
  backgroundsize: "100%";
  margin-left: 5%;
  margin-right: 5%;
  width: 40%;
  height: 300px;
  animation: ${show3} 40s linear 0s infinite;
`;

const Logo4 = styled.div`
  background: "url(/Partneri/4.png) no-repeat";
  backgroundsize: "100%";
  margin-left: 5%;
  margin-right: 5%;
  width: 40%;
  height: 300px;
  animation: ${show4} 40s linear 0s infinite;
`;

class AboutUs extends React.Component {
  render() {
    return (
      <div>
        <HeaderMobile />
        <div className="aboutUs">
          <a href="/">
            <img src="/logo.png" alt="logo" className="logoScroll" />
          </a>
          <Header />
          <div className="firtsAboutUs">
            <div className="MP_firtsAboutUs">
              <MiddlePage
                bigTitle={["UPOZNAJTE", "POLIPLAN!"]}
                titles={[
                  "ALL IN ONE",
                  "POLIPLAN U BROJEVIMA",
                  "CASE STUDY",
                  "NAŠ NAČIN",
                ]}
                texts={[
                  [
                    `Osnovani smo 2006. godine sa ciljem da odgovorimo na potrebe tržišta za kvalitetnom BTL egzekucijom. 
                     Posvećenošću, radom i trudom postali smo, ne samo najveća BTL agencija u regionu, već i agencija u kojoj danas možete dobiti 
                     „ALL IN ONE“ uslugu.`,
                  ],
                  [
                    `Imamo veliko zadovoljstvo da se tokom godina razvijamo zajedno sa brendovima naših partnera.
                    Danas lokalno pokrivamo tri tržišta: Srbiju, Severnu Makedoniju i Crnu Goru, a zajedno sa partnerima i ostatak regiona.
                    `,
                    `Ova pokrivenost dovodi do optimizacije troškova i ulaganja partnera sa kojima sarađujemo.`,
                  ],
                  [
                    `PROJEKAT EuroLeague Basketball Final Four Belgrade, 7Days`,
                    `Priča o jednom od najvećih i najkompleksnijih događaja ikada održanih u Beogradu!`,
                  ],
                  [
                    `Partner / Brief / Jel ste videli mejl? / Šta nam sve treba? / Kreativno rešenje/ Požuri! / Dizajn / Poštovani/dragi poslato / Odobreno / Kreni! / Produkcija / Događaj / Uspeh! / Izveštaj / Lekcija!....MI!`,
                  ],
                ]}
                type="about-us-text-hover"
                mobileImage="/upoznajtePolipolan.png"
                links={[
                  "allInOne",
                  "poliplanUBrojevima",
                  "caseStudy",
                  "nasNacin",
                ]}
              />
            </div>
            <div className="Slide_firtsAboutUs">
              <img
                src="/AllInOne/AllInOneSlide.png"
                className="page-img"
                alt="allInOne"
              />
            </div>
          </div>
          <div className="firtsAboutUs" id="nasaPorodica">
            <div className="MP_firtsAboutUs">
              <MiddlePage1
                title={["NAŠ TIM JE", "I VAŠ TIM!"]}
                texts={[
                  `„Ako želiš da ideš brzo, idi sam. Ako želiš da stigneš daleko, idi zajedno sa nekim.“`,
                  `Kombinujemo strategiju, kreativnost i razvoj. Puni smo energije, zalaganja i strasti. 
                                        Naš tim pruža kreativne koncepte i radi na najizvrsnijim projektima.`,
                  `Mi smo istraživači, kreativci, umetnici, analitičari, stratezi, advokati, ekonomisti, psiholozi, gejmeri, filmofili, mame i tate.`,
                  `Zajedno transformišemo obično u upečatljivo.`,
                ]}
                id="nasTim"
              />
            </div>
            <div className="nothing-mobile"></div>
            <div className="Slide_firtsAboutUs" style={{ marginTop: "15vh" }}>
              <SliderServices
                images={images}
                arrow="blackArrow"
                dots="noneDots"
              />
            </div>
          </div>
          <div className="nothing"></div>
          <div className="nothing"></div>

          <div className="page" id="nasiPrijatelji">
            <div className="page-left">
              <div
                className="page-title"
                id="page-title-about-us"
                style={{ lineHeight: "88%" }}
              >
                <span id="title-non-bold-about-us">NAŠI PARTNERI</span>
                <br />
                <span>
                  KAO PRIJATELJI <br /> NAŠE PORODICE
                </span>
              </div>
              <div
                className="page-right-mobile-partneri"
                style={{ height: "250px", objectFit: "contain" }}
              >
                <SliderServices
                  images={imagesPartneri}
                  arrow="blackArrow"
                  dots="noneDots"
                />
              </div>
              <div className="nothing" style={{ height: "10vh" }}></div>
              <div className="page-text-about-us">
                <span className="red-italic-text">
                  „Nije važno kuda idete već sa kim“
                </span>
              </div>
              <div className="page-text-about-us">
                Naravno da nas interesuje{" "}
                <span className="bold-word">KO, ŠTA, GDE, KADA i KAKO</span> -
                te informacije su neophodne za izradu bilo kog kreativnog
                koncepta. Ono što nas razlikuje od drugih jeste to što se naša
                vizuelna i marketinška rešenja najviše baziraju na onom{" "}
                <span className="bold-word">ZAŠTO</span> koje stoji iza vašeg
                brenda.
              </div>
              <div className="page-text-about-us">
                To je tajni začin svih naših kreativnih rešenja, i razlog
                njihovih neminovnih uspeha - razumemo da je vaše ZAŠTO
                specifično baš za vaš brend, i shodno tome kreiramo rešenja koja
                će na najbolji mogući način odgovarati vašoj strategiji, vašim
                resursima i rezultatima koje želite da postignete.
              </div>
              <div
                className="page-text-about-us"
                style={{ paddingBottom: "100px" }}
              >
                <span className="bold-word">
                  Otkrijte nam svoje ZAŠTO, a mi za Vas kreiramo KAKO.{" "}
                </span>
              </div>
            </div>

            <div className="page-right">
              <div className="page-right-first-row">
                <Logo1 />
                <Logo2 />
              </div>
              <div className="page-right-first-row">
                <Logo3 />
                <Logo4 />
              </div>
            </div>
          </div>
          <div className="nothing"></div>
          <div className="nothing"></div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
