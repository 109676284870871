import React from "react";
import "./../../css/General.css";
import SliderServices from "./SliderServices";

const images = [
  "/Digital/digital1.png",
  "/Digital/digital2.png",
  "/Digital/digital3.png",
  "/Digital/digital4.png",
];

class DrustveneMreze extends React.Component {
  render() {
    return (
      <div className="page" id="drustvene-mreze">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">DRUŠTVENE</span>
            <br />
            <span>MREŽE</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="blackArrow"
              dots="blackDots"
            />
          </div>
          <div className="page-text">
            <span className="red-italic-text">
              „Pred nama nije izbor da li ćemo biti na društvenim mrežama, već
              izbor da li ćemo to dobro raditi ili ne.“
            </span>
          </div>
          <div className="page-space"></div>

          <div className="page-text">
            Planiramo vaš digitalni nastup na svim društvenim mrežama. Vršimo
            produkciju kreativnog i zanimljivog sadržaja, poštujući identitet
            vašeg brenda i ton komunikacije, vodimo profile i komuniciramo sa
            vašim pratiocima.
          </div>
          <div className="page-text">
            <span className="bold-word">Oglašavanje:</span> <br />
            Kreiramo plaćene oglase na Facebooku, Instagramu i Googlu. Vršimo
            otvaranje ili preuzimanje vaših naloga za oglašavanje. Vodimo
            kampanje, predlažemo budžet, pratimo i analiziramo rezultate i
            izveštavamo vas o istim.
          </div>
        </div>

        <div className="page-right">
          <div className="page-slide">
            <SliderServices
              images={images}
              arrow="blackArrow"
              dots="blackDots"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DrustveneMreze;
