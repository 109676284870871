import React from "react";
import Header from "./Header";
import { __ } from "../utils/i18n";
import "./../css/Homepage.css";
import Screen1 from "./homepage/Screen1";
import Screen2 from "./homepage/Screen2";
import Screen3 from "./homepage/Screen3";
import { Animated } from "react-animated-css";
import Footer from "./Footer";
import HeaderMobile from "./HeaderMobile";
import Screen4 from "./homepage/Screen4";
import Screen5 from "./homepage/Screen5";
import Screen6 from "./homepage/Screen6";

class Homepage extends React.Component {
  constructor(init) {
    super(init);
    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    const position = document.getElementById("o-nama");
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < position.offsetTop - 100;
      if (isTop !== true) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
  }

  render() {
    return (
      <div>
        <HeaderMobile />
        <div className="homepage">
          <Header />
          <div className="firstAnimation">
            <Animated
              animationIn="fadeInLeft"
              animationInDuration={1000}
              animationOutDuration={0}
              isVisible={true}
            >
              <img className="imgHomepage" src="/homepage.jpg" alt="homepage" />
            </Animated>
          </div>
          <img className="img1to1" src="/1to1.png" alt="1to1" />

          <div className="titleHomepage">
            <span className="noBoldText">{__("CREATIVE MARKETING ")}</span>
            <span className="boldText">{__("&")}</span> <br />
            <span className="boldText">{__("ADVERTISING AGENCY")}</span>
          </div>

          <div className="textHomepage textHomepage-noResponsive">
            {__("WHEN")} {__("YOU")}
            <br />
            {__("REALLY NEED")} <br />
            <span id="turn">{__("RESULTS")}</span>
          </div>

          <div className="textHomepage textHomepage-responsive">
            {__("WHEN")} <br />
            {__("YOU")} <br />
            {__("REALLY")} <br />
            {__("NEED")} <br />
            <span id="turn">{__("RESULTS")}</span>
          </div>

          <img
            className={this.state.scrolled ? "logoScroll" : "logo"}
            src="/logo.png"
            alt="logo"
            id="logo-homepage"
          />
        </div>

        <div className="nothing"></div>
        <div className="nothing"></div>

        <Screen1 />

        <div className="nothing"></div>
        <div className="nothing"></div>

        <Screen2 />

        <div className="nothing"></div>
        <div className="nothing"></div>

        <Screen3 />

        <div className="nothing"></div>
        <div className="nothing"></div>
        <div className="nothing"></div>

        <Screen4 />

        <div className="nothing"></div>
        <div className="nothing"></div>

        <Screen6 />

        <div className="nothing"></div>
        <div className="nothing"></div>

        <Screen5 />

        <div className="nothing"></div>
        <div className="nothing"></div>

        <Footer />
      </div>
    );
  }
}

export default Homepage;
