import React from "react";
import { __ } from "../utils/i18n";
import "./../css/MiddlePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

class MiddlePage extends React.Component {
  constructor(init) {
    super(init);
    this.state = {
      HtmlText: [],
    };
  }

  openPage(page) {
    window.location.href = `/${page}`;
  }

  componentDidMount() {
    let HtmlText = [];
    for (let i = 0; i < this.props.titles.length; i++) {
      const { texts } = this.props;
      let textForTitle = [];
      if (this.props.titles[i] === "ALL IN ONE") {
        textForTitle.push(
          <div key={222} className={this.props.type} id="iskustvo">
            Osnovani smo 2006. godine sa ciljem da odgovorimo na potrebe tržišta
            za kvalitetnom BTL egzekucijom. Posvećenošću, radom i trudom postali
            smo, ne samo najveća BTL agencija u regionu, već i agencija u kojoj
            danas možete dobiti <span id="red-text">„ALL IN ONE“</span> uslugu.
          </div>
        );
      } else if (this.props.titles[i] === "CASE STUDY") {
        textForTitle.push(
          <div key={223} className={this.props.type} id="iskustvo">
            <span style={{ color: "#c30c0e", fontWeight: "600" }}>
              Projekat EuroLeague Basketball Final Four Belgrade, 7Days.
            </span>{" "}
            Priča o jednom od najvećih i najkompleksnijih događaja ikada
            održanih u Beogradu!
          </div>
        );
      } else {
        for (let j = 0; j < texts[i].length; j++) {
          textForTitle.push(
            <div key={j} className={this.props.type} id="iskustvo">
              {this.props.texts[i][j]}
            </div>
          );
        }
      }
      if (this.props.titles[i] === "POLIPLAN U BROJEVIMA") {
        HtmlText.push(
          <div key={i}>
            <span
              key={i}
              className="hover-element"
              style={{ lineHeight: "85%" }}
              onClick={this.openPage.bind(this, this.props.links[i])}
            >
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-iskustvo"
                className="caret-right"
              />
              {__("POLIPLAN U")} <br />
              {__("BROJEVIMA")}
              {textForTitle}
            </span>
          </div>
        );
      } else if (this.props.links[i]) {
        HtmlText.push(
          <div key={i}>
            <span
              key={i}
              className="hover-element"
              onClick={this.openPage.bind(this, this.props.links[i])}
            >
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-iskustvo"
                className="caret-right"
              />
              {__(this.props.titles[i])}
              {textForTitle}
            </span>
          </div>
        );
      } else {
        HtmlText.push(
          <div key={i}>
            <span key={i} className="hover-element">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-iskustvo"
                className="caret-right"
              />
              {__(this.props.titles[i])}
              {textForTitle}
            </span>
          </div>
        );
      }
    }
    this.setState({
      HtmlText: HtmlText,
    });
  }

  render() {
    let mobileImg = (
      <img
        className="imgMigglePage"
        src={this.props.mobileImage}
        alt="AboutUsImage"
      />
    );

    return (
      <div>
        <div className="middlePage">
          {mobileImg}
          <div className="titleLeft">
            <p className="titlesAboutUs">
              {__(this.props.bigTitle[0])} <br />
              <span className="bold">{__(this.props.bigTitle[1])}</span>
            </p>
            <div className="about-us-text">{this.state.HtmlText}</div>
          </div>
        </div>
        <div className="page" id="mobile-middle-page">
          <div className="page-left">
            <div className="page-title">
              <span id="title-non-bold">UPOZNAJTE</span>
              <br />
              <span>POLIPLAN</span>
            </div>
            <div className="page-right-mobile">
              <img
                src="/AllInOne/AllInOne.png"
                className="page-img"
                alt="allInOne"
              />
            </div>
            <div className="titleLeft" style={{ paddingBottom: "150px" }}>
              <div className="about-us-text">{this.state.HtmlText}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MiddlePage;
