import React from "react";
import Header from "./Header";
import "./../css/General.css";
import "./../css/AllInOne.css";
import HeaderMobile from "./HeaderMobile";

class AllInOne extends React.Component {
  render() {
    return (
      <div>
        <HeaderMobile />
        <Header />
        <a href="/">
          <img className="logoScroll" src="/logo.png" alt="logo" />
        </a>
        <div className="page">
          <div className="page-left">
            <div className="page-title">
              <span id="title-non-bold">ALL IN </span>
              <br />
              <span>ONE</span>
            </div>
            <div className="page-right-mobile">
              <img
                src="/AllInOne/AllInOneMobile.png"
                className="page-img"
                alt="allInOne"
              />
            </div>
            <div className="page-text">
              <span className="bold-word">Naša misija</span> je da{" "}
              <span className="red-italic-text">„ALL IN ONE“</span> pristupom
              našim partnerima omogućimo najkvalitetnija, najbolja i
              najkreativnija marketinška rešenja na jednom mestu.
            </div>

            <div className="page-text" style={{ paddingBottom: "100px" }}>
              <span className="bold-word">Vizija</span> naše agencije je da
              budemo sinonim za kompaniju koja može da pruži sve marketinške
              usluge na jednom mestu po najvišim standardima.
            </div>
          </div>
          <div className="nothing"></div>
          <div className="nothing"></div>
          <div className="nothing"></div>
          <div className="nothing"></div>

          <div className="page-right" id="page-right-all-in-one">
            <img
              src="/AllInOne/AllInOne.png"
              className="page-img"
              alt="allInOne"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AllInOne;
