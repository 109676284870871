import React from "react";
import "./../../css/General.css";
import SliderServices from "./SliderServices";

const images = [
  "/VideoProdukcija/01.jpg",
  "/VideoProdukcija/02.jpg",
  "/VideoProdukcija/03.jpg",
  "/VideoProdukcija/04.jpg",
  "/VideoProdukcija/05.jpg",
  "/VideoProdukcija/06.jpg",
  "/VideoProdukcija/07.jpg",
  "/VideoProdukcija/08.jpg",
  "/VideoProdukcija/09.jpg",
  "/VideoProdukcija/10.jpg",
  "/VideoProdukcija/11.jpg",
  "/VideoProdukcija/12.jpg",
];

class VideoProdukcija extends React.Component {
  render() {
    return (
      <div className="page" id="video-produkcija">
        <div className="page-left">
          <div className="page-title" style={{ paddingBottom: "50px" }}>
            <span id="title-non-bold">VIDEO</span>
            <br />
            <span>PRODUKCIJA</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
          <div className="page-text">
            Bavimo se video produkcijom, postprodukcijskim uslugama, uključujući
            i studijsko fotografisanje.
          </div>
          <div className="page-text">
            Kreiramo korporativne i event video snimke, kao i videe za društvene
            mreže, plaćene oglase i reklame. Učinite vaš događaj nezaboravnim sa
            našom 360 kamerom.  
          </div>
        </div>

        <div className="page-right">
          <div className="page-slide">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default VideoProdukcija;
