import React from "react";
import "./../css/Slide.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

let numOfSlider = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
let changeSlider = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
let slider = [false, false, false, false, false, false, false, false, false];

class Slide extends React.Component {
  constructor() {
    super();
    this.state = {
      counter: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      intervalId: 0,
    };
  }

  componentDidMount() {
    this.setState({
      intervalId: setInterval(this.timer.bind(this), 3000),
    });
  }

  timer() {
    if (changeSlider[this.props.rbSlider]) {
      changeSlider[this.props.rbSlider] = false;
      slider[this.props.rbSlider] = true;
      let pom = this.state.counter;
      pom[this.props.rbSlider] = numOfSlider[this.props.rbSlider];
      this.setState({
        counter: pom,
      });
    } else if (slider[this.props.rbSlider]) {
      document.getElementById(
        "radio" + numOfSlider[this.props.rbSlider] + this.props.sliderNumber
      ).checked = true;
      slider[this.props.rbSlider] = false;
      if (numOfSlider[this.props.rbSlider] + 1 > 4) {
        let pom = this.state.counter;
        pom[this.props.rbSlider] = 1;
        this.setState({
          counter: pom,
        });
      } else {
        let pom = this.state.counter;
        pom[this.props.rbSlider] = numOfSlider[this.props.rbSlider] + 1;
        this.setState({
          counter: pom,
        });
      }
    } else {
      document.getElementById(
        "radio" +
          this.state.counter[this.props.rbSlider] +
          this.props.sliderNumber
      ).checked = true;
      if (this.state.counter[this.props.rbSlider] + 1 > 4) {
        let pom = this.state.counter;
        pom[this.props.rbSlider] = 1;
        this.setState({
          counter: pom,
        });
      } else {
        let pom = this.state.counter;
        pom[this.props.rbSlider] = this.state.counter[this.props.rbSlider] + 1;
        this.setState({
          counter: pom,
        });
      }
    }
  }

  nextImage() {
    let currentSlide = this.state.counter;
    if (currentSlide[this.props.rbSlider] + 1 > 4) {
      numOfSlider[this.props.rbSlider] = 1;
    } else {
      numOfSlider[this.props.rbSlider] = currentSlide[this.props.rbSlider] + 1;
    }

    changeSlider[this.props.rbSlider] = true;
    document.getElementById(
      "radio" + numOfSlider[this.props.rbSlider] + this.props.sliderNumber
    ).checked = true;

    if (currentSlide[this.props.rbSlider] + 1 > 4) {
      let pom = this.state.counter;
      pom[this.props.rbSlider] = 1;
      this.setState({
        counter: pom,
      });
    } else {
      let pom = this.state.counter;
      pom[this.props.rbSlider] = this.state.counter[this.props.rbSlider] + 1;
      this.setState({
        counter: pom,
      });
    }
  }

  prewImage() {
    let currentSlide = this.state.counter;
    if (currentSlide[this.props.rbSlider] - 1 < 1) {
      numOfSlider[this.props.rbSlider] = 4;
    } else {
      numOfSlider[this.props.rbSlider] = currentSlide[this.props.rbSlider] - 1;
    }
    changeSlider[this.props.rbSlider] = true;
    document.getElementById(
      "radio" + numOfSlider[this.props.rbSlider] + this.props.sliderNumber
    ).checked = true;

    if (currentSlide[this.props.rbSlider] - 1 < 1) {
      let pom = this.state.counter;
      pom[this.props.rbSlider] = 4;
      this.setState({
        counter: pom,
      });
    } else {
      let pom = this.state.counter;
      pom[this.props.rbSlider] = this.state.counter[this.props.rbSlider] - 1;
      this.setState({
        counter: pom,
      });
    }
  }

  firstRadioBtn() {
    numOfSlider[this.props.rbSlider] = 1;
    changeSlider[this.props.rbSlider] = true;
    let pom = this.state.counter;
    pom[this.props.rbSlider] = 1;
    this.setState({
      counter: pom,
    });
  }

  secondRadioBtn() {
    numOfSlider[this.props.rbSlider] = 2;
    changeSlider[this.props.rbSlider] = true;
    let pom = this.state.counter;
    pom[this.props.rbSlider] = 2;
    this.setState({
      counter: pom,
    });
  }

  thirdRadioBtn() {
    numOfSlider[this.props.rbSlider] = 3;
    changeSlider[this.props.rbSlider] = true;
    let pom = this.state.counter;
    pom[this.props.rbSlider] = 3;
    this.setState({
      counter: pom,
    });
  }

  fourthRadioBtn() {
    numOfSlider[this.props.rbSlider] = 4;
    changeSlider[this.props.rbSlider] = true;
    let pom = this.state.counter;
    pom[this.props.rbSlider] = 4;
    this.setState({
      counter: pom,
    });
  }

  render() {
    return (
      <div className={this.props.height ? "body-height" : "body"}>
        <div className="Slider">
          <div className="slides">
            <input
              type="radio"
              name={this.props.sliderName}
              id={this.props.sliderId[0]}
            />
            <input
              type="radio"
              name={this.props.sliderName}
              id={this.props.sliderId[1]}
            />
            <input
              type="radio"
              name={this.props.sliderName}
              id={this.props.sliderId[2]}
            />
            <input
              type="radio"
              name={this.props.sliderName}
              id={this.props.sliderId[3]}
            />

            <div className="slide first">
              <img src={this.props.images[0]} alt="" />
            </div>
            <div className="slide">
              <img src={this.props.images[1]} alt="" />
            </div>
            <div className="slide">
              <img src={this.props.images[2]} alt="" />
            </div>
            <div className="slide">
              <img src={this.props.images[3]} alt="" />
            </div>

            <div className="navigation-auto">
              <div className="auto-btn1"></div>
              <div className="auto-btn2"></div>
              <div className="auto-btn3"></div>
              <div className="auto-btn4"></div>
            </div>

            <div className="navigation-manual">
              <label
                htmlFor={this.props.sliderId[0]}
                className="manual-btn"
                onClick={this.firstRadioBtn.bind(this)}
              ></label>
              <label
                htmlFor={this.props.sliderId[1]}
                className="manual-btn"
                onClick={this.secondRadioBtn.bind(this)}
              ></label>
              <label
                htmlFor={this.props.sliderId[2]}
                className="manual-btn"
                onClick={this.thirdRadioBtn.bind(this)}
              ></label>
              <label
                htmlFor={this.props.sliderId[3]}
                className="manual-btn"
                onClick={this.fourthRadioBtn.bind(this)}
              ></label>
            </div>

            <div className="arrow">
              <button className="prew" onClick={this.prewImage.bind(this)}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>
              <button className="next" onClick={this.nextImage.bind(this)}>
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Slide;
