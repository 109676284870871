import React from "react";
import { __ } from "../utils/i18n";
import "./../css/MiddlePage1.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import SliderServices from "./services/SliderServices";
const images = [
  "/slikePoliplan/Bojan-Donevski-General-Manager.jpg",
  "/slikePoliplan/Aleksandar-Racic-–-Executive-director.jpg",
  "/slikePoliplan/Martina-Stošić-Group-Account-Director.jpg",
  "/slikePoliplan/Slaven-Buha-–-Events-and-Digital-Communications-Director.jpg",
  "/slikePoliplan/Marta-Klar-–-Creative-Director.jpg",
  "/slikePoliplan/Miroslav-Orlović-–-Production-Director.jpg",
  "/slikePoliplan/Ružica-Visković-–-Account-Director.jpg",
  "/slikePoliplan/Marko-Purar-–-BTL-Manager.jpg",
  "/slikePoliplan/Nikola-Vitas-–-Senior-Concept-Designer.jpg",
  "/slikePoliplan/Jelena-Delić-–-Prepress-Designer.jpg",
  "/slikePoliplan/Marija-Đorđević-–-BTL-Executive.jpg",
  "/slikePoliplan/Maja-Ranković-–-BTL-Executive.jpg",
  "/slikePoliplan/Jasmina-Sekulović-–-Digital-Communications-Executive.jpg",
  "/slikePoliplan/Nevena-Tomić--Digital-Communications-Manager.jpg",
  "/slikePoliplan/Marijana-Stojanović-–-Senior-BTL-Manager.jpg",
  "/slikePoliplan/Nataša-Sajić----BTL-Assistant.jpg",
  "/slikePoliplan/Saša-Tepšić-–-BTL-Executive.jpg",
  "/slikePoliplan/Anja-Krsmanović-–-Events-and-Activations-Executive.jpg",
  "/slikePoliplan/Milica-Ilić-–-BTL-Executive.jpg",
  "/slikePoliplan/Dajana-Mitić-–-Junior-Graphic-Designer.jpg",
  "/slikePoliplan/Miloš-Mihajlović---Senior-BTL-Executive.jpg",
  "/slikePoliplan/Marijana-Miletić-–-Senior-Graphic-Designer.jpg",
  "/slikePoliplan/Ljubomir-Stamenkovic-01.jpg",
  "/slikePoliplan/Milica-01.jpg",
  "/slikePoliplan/Tamara-Lazarević-–-Events-and-Activations-Executive.jpg",
  "/slikePoliplan/Danilo-Damjanović---Production-Manager.jpg",
  "/slikePoliplan/Dragan-Novaković-–-Production-Executive.jpg",
  "/slikePoliplan/Vladimir-Kasikovic-01.jpg",
];
class MiddlePage1 extends React.Component {
  constructor(init) {
    super(init);
    this.state = {
      text: [],
    };
  }

  goToEmployees() {
    window.location.href = `/nasTim`;
  }
  componentDidMount() {
    const { texts } = this.props;
    let text = [];
    for (let i = 0; i < texts.length; i++) {
      text.push(<div key={i}>{texts[i]}</div>);
      text.push(<br key={i + texts.length}></br>);
    }
    if (
      this.props.id !== "videoProdukcija" &&
      this.props.id !== "stuffLeasing" &&
      this.props.id !== "allInOne"
    ) {
      text[0] = (
        <div
          key={0}
          style={{
            fontStyle: "italic",
            fontWeight: "900",
            color: "#c30c0e",
            fontSize: "2.5vh",
          }}
        >
          {texts[0]}
        </div>
      );
    }

    if (this.props.id === "nasTim") {
      text[6] = (
        <div key={9} style={{ fontWeight: "bold" }}>
          {texts[3]}
        </div>
      );
      text[7] = (
        <div key={7}>
          <br></br>
          <button
            className="button-vise"
            onClick={this.goToEmployees.bind(this)}
            style={{ fontSize: "15px" }}
          >
            {" "}
            VIŠE
            <FontAwesomeIcon
              icon={faCaretRight}
              className="caret-right-button-screen3"
            />
          </button>
        </div>
      );
    }
    this.setState({
      text,
    });
  }

  render() {
    return (
      <div>
        <div className="middlePage1" id={this.props.id}>
          <img
            className="imgMiddlePage1"
            src="/Stuff/StuffMobile.jpg"
            alt="AboutUsImage"
          />
          <div className="titleLeft">
            <p className="titlesAboutUs">
              {__(this.props.title[0])} <br />
              <span className="bold">{__(this.props.title[1])}</span>
            </p>
            <div className="about-us-text">{this.state.text}</div>
          </div>
        </div>

        <div className="page" id="mobile-middle-page">
          <div className="page-left">
            <div className="page-title">
              <span id="title-non-bold">NAŠ TIM JE</span>
              <br />
              <span>I VAŠ TIM!</span>
            </div>
            <div className="page-right-mobile-partneri">
              <SliderServices
                images={images}
                arrow="blackArrow"
                dots="noneDots"
              />
            </div>
            <div className="page-text">
              <span className="red-italic-text">
                „Ako želiš da ideš brzo, idi sam. Ako želiš da stigneš daleko,
                idi zajedno sa nekim.“{" "}
              </span>
            </div>
            <div className="page-text">
              Kombinujemo strategiju, kreativnost i razvoj. Puni smo energije,
              zalaganja i strasti. Naš tim pruža kreativne koncepte i radi na
              najizvrsnijim projektima.
            </div>
            <div className="page-text">
              Mi smo istraživači, kreativci, umetnici, analitičari, stratezi,
              advokati, ekonomisti, psiholozi, gejmeri, filmofili, mame i tate.
            </div>
            <div className="page-text">
              <span className="bold-word">
                Zajedno transformišemo obično u upečatljivo.
              </span>
            </div>
            <div className="button-screen3">
              <a href="/nasTim">
                <button className="button-tema">
                  VIŠE
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="caret-right-button-screen3"
                  />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MiddlePage1;
