import React from "react";
import Header from "./Header";
import "./../css/OurTeam.css";
import HeaderMobile from "./HeaderMobile";

class OurTeam extends React.Component {
  zoomImage(name) {
    let element = document.getElementById(name);
    element.style.display = "block";
    element.style.fontSize = "20px";
  }

  outImage(name) {
    let element = document.getElementById(name);
    element.style.display = "none";
  }

  render() {
    return (
      <div>
        <Header />
        <HeaderMobile />
        <a href="/">
          <img className="logoScroll" src="/logo.png" alt="logo" />
        </a>
        <div className="clanovi_timovi" id="prvi_red">
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "bojanDonevski")}
              onMouseLeave={this.outImage.bind(this, "bojanDonevski")}
            >
              <img
                src="/slikePoliplan/Bojan-Donevski-General-Manager.jpg"
                alt="clan"
              />
              <div className="position" id="bojanDonevski">
                {" "}
                Bojan Donevski <br />
                General Manager
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "aleksandarRačić")}
              onMouseLeave={this.outImage.bind(this, "aleksandarRačić")}
            >
              <img
                src="/slikePoliplan/Aleksandar-Racic-–-Executive-director.jpg"
                alt="clan"
              />
              <div className="position" id="aleksandarRačić">
                {" "}
                Aleksandar Racić <br />
                Executive director
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "martinaStošić")}
              onMouseLeave={this.outImage.bind(this, "martinaStošić")}
            >
              <img
                src="/slikePoliplan/Martina-Stošić-Group-Account-Director.jpg"
                alt="clan"
              />
              <div className="position" id="martinaStošić">
                {" "}
                Martina Stošić <br />
                Group Account Director
              </div>
            </div>
          </div>

          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "slavenBuha")}
              onMouseLeave={this.outImage.bind(this, "slavenBuha")}
            >
              <img
                src="/slikePoliplan/Slaven-Buha-–-Events-and-Digital-Communications-Director.jpg"
                alt="clan"
              />
              <div className="position" id="slavenBuha">
                {" "}
                Slaven Buha <br />
                Events and Digital Communications Director
              </div>
            </div>
          </div>
        </div>
        <div className="clanovi_timovi">
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "martaKlar")}
              onMouseLeave={this.outImage.bind(this, "martaKlar")}
            >
              <img
                src="/slikePoliplan/Marta-Klar-–-Creative-Director.jpg"
                alt="clan"
              />
              <div className="position" id="martaKlar">
                {" "}
                Marta Klar <br />
                Creative Director
              </div>
            </div>
          </div>

          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "miroslavOrlović")}
              onMouseLeave={this.outImage.bind(this, "miroslavOrlović")}
            >
              <img
                src="/slikePoliplan/Miroslav-Orlović-–-Production-Director.jpg"
                alt="clan"
              />
              <div className="position" id="miroslavOrlović">
                {" "}
                Miroslav Orlović <br />
                Production Director
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "ružicaVisković")}
              onMouseLeave={this.outImage.bind(this, "ružicaVisković")}
            >
              <img
                src="/slikePoliplan/Ružica-Visković-–-Account-Director.jpg"
                alt="clan"
              />
              <div className="position" id="ružicaVisković">
                {" "}
                Ružica Visković <br />
                Account Director
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "markoPurar")}
              onMouseLeave={this.outImage.bind(this, "markoPurar")}
            >
              <img
                src="/slikePoliplan/Marko-Purar-–-BTL-Manager.jpg"
                alt="clan"
              />
              <div className="position" id="markoPurar">
                Marko Purar <br />
                BTL Manager
              </div>
            </div>
          </div>
        </div>
        <div className="clanovi_timovi">
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "nikolaVitas")}
              onMouseLeave={this.outImage.bind(this, "nikolaVitas")}
            >
              <img
                src="/slikePoliplan/Nikola-Vitas-–-Senior-Concept-Designer.jpg"
                alt="clan"
              />
              <div className="position" id="nikolaVitas">
                {" "}
                Nikola Vitas <br />
                Senior Concept Designer
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "jelenaDelic")}
              onMouseLeave={this.outImage.bind(this, "jelenaDelic")}
            >
              <img
                src="/slikePoliplan/Jelena-Delić-–-Prepress-Designer.jpg"
                alt="clan"
              />
              <div className="position" id="jelenaDelic">
                {" "}
                Jelena Delić <br />
                Prepress Designer
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "marijaDjordjevic")}
              onMouseLeave={this.outImage.bind(this, "marijaDjordjevic")}
            >
              <img
                src="/slikePoliplan/Marija-Đorđević-–-BTL-Executive.jpg"
                alt="clan"
              />
              <div className="position" id="marijaDjordjevic">
                {" "}
                Marija Đorđević <br />
                BTL Executive
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "majaRankovic")}
              onMouseLeave={this.outImage.bind(this, "majaRankovic")}
            >
              <img
                src="/slikePoliplan/Maja-Ranković-–-BTL-Executive.jpg"
                alt="clan"
              />
              <div className="position" id="majaRankovic">
                {" "}
                Maja Ranković <br />
                BTL Executive
              </div>
            </div>
          </div>
        </div>

        <div className="clanovi_timovi">
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "jasminaSekulović")}
              onMouseLeave={this.outImage.bind(this, "jasminaSekulović")}
            >
              <img
                src="/slikePoliplan/Jasmina-Sekulović-–-Digital-Communications-Executive.jpg"
                alt="clan"
              />
              <div className="position" id="jasminaSekulović">
                {" "}
                Jasmina Sekulović <br />
                Digital Communications Executive
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "nevenaTomic")}
              onMouseLeave={this.outImage.bind(this, "nevenaTomic")}
            >
              <img
                src="/slikePoliplan/Nevena-Tomić--Digital-Communications-Manager.jpg"
                alt="clan"
              />
              <div className="position" id="nevenaTomic">
                {" "}
                Nevena Tomić <br />
                Digital Communications Manager
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "marijanaStojanović")}
              onMouseLeave={this.outImage.bind(this, "marijanaStojanović")}
            >
              <img
                src="/slikePoliplan/Marijana-Stojanović-–-Senior-BTL-Manager.jpg"
                alt="clan"
              />
              <div className="position" id="marijanaStojanović">
                {" "}
                Marijana Stojanović <br />
                Senior BTL Manager
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "natašaSajić")}
              onMouseLeave={this.outImage.bind(this, "natašaSajić")}
            >
              <img
                src="/slikePoliplan/Nataša-Sajić----BTL-Assistant.jpg"
                alt="clan"
              />
              <div className="position" id="natašaSajić">
                {" "}
                Nataša Sajić <br />
                BTL Assistant
              </div>
            </div>
          </div>
        </div>
        <div className="clanovi_timovi">
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "sasaTepic")}
              onMouseLeave={this.outImage.bind(this, "sasaTepic")}
            >
              <img
                src="/slikePoliplan/Saša-Tepšić-–-BTL-Executive.jpg"
                alt="clan"
              />
              <div className="position" id="sasaTepic">
                {" "}
                Saša Tepšić <br />
                BTL Executive
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "anjaKrsmanović")}
              onMouseLeave={this.outImage.bind(this, "anjaKrsmanović")}
            >
              <img
                src="/slikePoliplan/Anja-Krsmanović-–-Events-and-Activations-Executive.jpg"
                alt="clan"
              />
              <div className="position" id="anjaKrsmanović">
                {" "}
                Anja Krsmanović <br />
                Events and Activations Manager
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "draganNovakovic")}
              onMouseLeave={this.outImage.bind(this, "draganNovakovic")}
            >
              <img
                src="/slikePoliplan/Dragan-Novaković-–-Production-Executive.jpg"
                alt="clan"
              />
              <div className="position" id="draganNovakovic">
                {" "}
                Dragan Novaković <br />
                Production Executive
              </div>
            </div>
          </div>

          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "dajanaMitic")}
              onMouseLeave={this.outImage.bind(this, "dajanaMitic")}
            >
              <img
                src="/slikePoliplan/Dajana-Mitić-–-Junior-Graphic-Designer.jpg"
                alt="clan"
              />
              <div className="position" id="dajanaMitic">
                {" "}
                Dajana Mitić <br /> Junior Graphic Designer
              </div>
            </div>
          </div>
        </div>
        <div className="clanovi_timovi">
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "milošMihajlović")}
              onMouseLeave={this.outImage.bind(this, "milošMihajlović")}
            >
              <img
                src="/slikePoliplan/Miloš-Mihajlović---Senior-BTL-Executive.jpg"
                alt="clan"
              />
              <div className="position" id="milošMihajlović">
                {" "}
                Miloš Mihajlović <br />
                Senior BTL Executive
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div className="container">
              <img
                src="/slikePoliplan/Marijana-Miletić-–-Senior-Graphic-Designer.jpg"
                alt="clan"
                onMouseOver={this.zoomImage.bind(this, "marijanaMiletic")}
                onMouseLeave={this.outImage.bind(this, "marijanaMiletic")}
              />
              <div className="position" id="marijanaMiletic">
                {" "}
                Marijana Miletić <br /> Senior Graphic Designer
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "ljubomirStamenkovic")}
              onMouseLeave={this.outImage.bind(this, "ljubomirStamenkovic")}
            >
              <img
                src="/slikePoliplan/Ljubomir-Stamenkovic-01.jpg"
                alt="clan"
              />
              <div className="position" id="ljubomirStamenkovic">
                {" "}
                Ljubomir Stamenković <br />
                Senior Industrial Designer
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "milicaLasica")}
              onMouseLeave={this.outImage.bind(this, "milicaLasica")}
            >
              <img src="/slikePoliplan/Milica-01.jpg" alt="clan" />
              <div className="position" id="milicaLasica">
                {" "}
                Milica Lasica <br />
                Account Director
              </div>
            </div>
          </div>
        </div>
        <div className="clanovi_timovi"></div>
        <div className="clanovi_timovi">
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "tamaraLazarević")}
              onMouseLeave={this.outImage.bind(this, "tamaraLazarević")}
            >
              <img
                src="/slikePoliplan/Tamara-Lazarević-–-Events-and-Activations-Executive.jpg"
                alt="clan"
              />
              <div className="position" id="tamaraLazarević">
                {" "}
                Tamara Lazarević <br />
                Events and Activations Manager
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "daniloDamjanović")}
              onMouseLeave={this.outImage.bind(this, "daniloDamjanović")}
            >
              <img
                src="/slikePoliplan/Danilo-Damjanović---Production-Manager.jpg"
                alt="clan"
              />
              <div className="position" id="daniloDamjanović">
                {" "}
                Danilo Damjanović <br />
                Production Manager
              </div>
            </div>
          </div>

          <div className="clan_t">
            <div
              className="container"
              onMouseOver={this.zoomImage.bind(this, "vladimirKasikovic")}
              onMouseLeave={this.outImage.bind(this, "vladimirKasikovic")}
            >
              <img src="/slikePoliplan/Vladimir-Kasikovic-01.jpg" alt="clan" />
              <div className="position" id="vladimirKasikovic">
                {" "}
                Vladimir Kašiković <br />
                Graphic Designer
              </div>
            </div>
          </div>
          <div className="clan_t">
            <div className="container" id="logo">
              <img src="/logo.png" alt="clan" />
            </div>
          </div>
        </div>

        <div className="nothing"></div>
      </div>
    );
  }
}

export default OurTeam;
