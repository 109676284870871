import React from "react";
import "./../../css/General.css";
import SliderServices from "./SliderServices";

const images = [
  "/KreativniKoncepti/kreativniKoncept1.jpg",
  "/KreativniKoncepti/kreativniKoncept2.jpg",
  "/KreativniKoncepti/kreativniKoncept3.jpg",
  "/KreativniKoncepti/kreativniKoncept4.jpg",
];

class KreativniKoncepti extends React.Component {
  render() {
    return (
      <div className="page" id="kreativni-koncepti">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">KREATIVNI</span>
            <br />
            <span>KONCEPTI</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
          <div className="page-text">
            <span className="red-italic-text">
              „Da bi imao veliku ideju, moraš imati puno ideja.“
            </span>
          </div>
          <div className="page-space"></div>

          <div className="page-text">
            Pripremamo kreativne koncepte za sve vrste marketing kampanja,
            reklamne materijale i organizaciju događaja. Kreativne, a
            zahvaljujući našem dugogodišnjem iskustvu i ekspertizi, realne i
            ostvarive.
          </div>
        </div>

        <div className="page-right">
          <div className="page-slide">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default KreativniKoncepti;
