import React from "react";
import "../css/Counting.css";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import SliderServices from "./services/SliderServices";

const images = [
  "/CaseStudy/1.png",
  "/CaseStudy/79.png",
  "/CaseStudy/106.png",
  "/CaseStudy/117.png",
  "/CaseStudy/122.png",
  "/CaseStudy/136.png",
  "/CaseStudy/137.png",
  "/CaseStudy/162.png",
  "/CaseStudy/225.png",
  "/CaseStudy/777.png",
];

const CountingPage = () => {
  return (
    <div className="page">
      <HeaderMobile />
      <a href="/">
        <img className="logoScroll" src="/whiteLogo.png" alt="logo" />
      </a>
      <Header />
      <div className="text-in-case-study">
        <div className="row-case-study">
          <div className="page-title">
            <span id="title-non-bold">CASE</span>
            <br />
            <span>STUDY</span>
          </div>
          <div className="page-text">
            <span className="page-text-case-study">
              Projekat <br />
              ELB Final Four Belgrade, 7 days
            </span>
          </div>
          <div className="page-text">
            <span className="page-text-case-study"></span>
          </div>
          <div className="img-case-study">
            <img
              src="/CaseStudy/2018_EuroLeague_Final_Four.png"
              id="evroliga"
              alt="case-study"
            />
          </div>
          <div className="img-case-study">
            <div className="one-image-case-study">
              <img
                src="/CaseStudy/logo-7days.png"
                id="days-logo"
                alt="case-study"
              />
            </div>
            <div className="one-image-case-study">
              <img
                src="/CaseStudy/761.png"
                id="evroliga-logo"
                alt="case-study"
              />
            </div>
          </div>
        </div>
        <div className="row-case-study" style={{ width: "27%" }}>
          <div
            className="page-text"
            style={{
              marginTop: "10vh",
              lineHeight: "190%",
              textAlign: "right",
            }}
          >
            <span
              className="page-text-case-study"
              style={{ fontSize: "40px", fontWeight: "800" }}
            >
              KREATIVNA <br />
              REŠENJA, <br />
              AKTIVACIJE I <br />
              PRODUKCIJA
            </span>
          </div>

          <div
            className="page-text"
            style={{
              lineHeight: "160%",
              textAlign: "right",
              marginTop: "5vh",
            }}
          >
            <span
              className="page-text-case-study"
              style={{ fontSize: "40px", fontWeight: "800" }}
            >
              <span style={{ fontSize: "65px" }}>FUN</span> <br />
              ZONE
            </span>
          </div>

          <div
            className="page-text"
            style={{
              lineHeight: "160%",
              textAlign: "right",
              marginTop: "5vh",
            }}
          >
            <span
              className="page-text-case-study"
              style={{ fontSize: "40px", fontWeight: "800" }}
            >
              <span style={{ fontSize: "65px" }}>VIP</span> <br />
              ZONE
            </span>
          </div>
        </div>

        <div className="row-case-study" style={{ width: "27%" }}>
          <div
            className="page-text"
            style={{
              marginTop: "8vh",
              textAlign: "right",
            }}
          >
            <div
              className="page-text-case-study"
              style={{ fontSize: "60px", fontWeight: "800" }}
            >
              9500 <br />
              <div
                style={{
                  fontSize: "35px",
                  fontWeight: "400",
                  lineHeight: "90%",
                }}
              >
                PODELJENIH <br />
                SAMPLOVA
              </div>
            </div>
          </div>

          <div
            className="page-text"
            style={{
              marginTop: "2vh",
              textAlign: "right",
            }}
          >
            <div
              className="page-text-case-study"
              style={{ fontSize: "60px", fontWeight: "800" }}
            >
              20000 <br />
              <div
                style={{
                  fontSize: "35px",
                  fontWeight: "400",
                  lineHeight: "90%",
                }}
              >
                UČESNIKA U <br />
                AKTIVACIJAMA
              </div>
            </div>
          </div>

          <div
            className="page-text"
            style={{
              marginTop: "2vh",
              textAlign: "right",
            }}
          >
            <div
              className="page-text-case-study"
              style={{ fontSize: "60px", fontWeight: "800" }}
            >
              <span
                style={{
                  fontSize: "35px",
                  fontWeight: "400",
                }}
              >
                PREKO
              </span>
              70 <br />
              <div
                style={{
                  fontSize: "35px",
                  fontWeight: "400",
                  lineHeight: "90%",
                }}
              >
                PROMOTERA
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-slide-study" id="page-case-study">
        <SliderServices images={images} arrow="blackArrow" dots="whiteDots" />
      </div>
      <div className="case-study-mobile">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">CASE</span>
            <br />
            <span>STUDY</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
          <div className="page-text" style={{ paddingBottom: "0px" }}>
            <span style={{ fontSize: "20px", fontWeight: "900" }}>
              KREATIVNA REŠENJA, AKTIVACIJE I PRODUKCIJA
            </span>
          </div>
          <div className="page-text" style={{ paddingBottom: "0px" }}>
            <span style={{ fontSize: "20px", fontWeight: "900" }}>
              FUN ZONE
            </span>
          </div>
          <div className="page-text" style={{ paddingBottom: "0px" }}>
            <span style={{ fontSize: "20px", fontWeight: "900" }}>
              VIP ZONE
            </span>
          </div>

          <div className="page-text" style={{ paddingBottom: "0px" }}>
            <span style={{ fontSize: "20px", fontWeight: "900" }}>
              9500 PODELJENIH SAMPLOVA
            </span>
          </div>

          <div className="page-text" style={{ paddingBottom: "0px" }}>
            <span style={{ fontSize: "20px", fontWeight: "900" }}>
              20000 UČESNIKA U AKTIVACIJAMA
            </span>
          </div>

          <div className="page-text" style={{ paddingBottom: "20px" }}>
            <span style={{ fontSize: "20px", fontWeight: "900" }}>
              PREKO 70 PROMOTERA
            </span>
          </div>
          <div style={{ height: "10vh" }}></div>
        </div>
      </div>
    </div>
  );
};

export default CountingPage;
