import React from "react";
import { __ } from "./../../utils/i18n";
import "./../../css/Screen2.css";
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

class Screen2 extends React.Component {
  constructor(init) {
    super(init);
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    const position = document.getElementById("usluge");
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < position.offsetTop - 100;
      if (isTop !== true) {
        this.setState({ isVisible: true });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="screen2" id="usluge">
          <div
            style={{ width: "57%" }}
            className={this.state.isVisible ? "hr-line" : "notDisplay"}
          ></div>
          <div className="textONama">Usluge</div>

          <div
            className={this.state.isVisible ? "title-screen2" : "notDisplay"}
          >
            <span className="no-bold-text">{__("OTKLJUČAJTE VRATA")}</span>{" "}
            <br />
            <span className="bold-text">{__("NAJBOLJIH IDEJA!")}</span>
          </div>

          <div className="firstAnimation">
            <Animated
              animationIn="fadeInLeft"
              animationInDuration={1800}
              animationOutDuration={0}
              isVisible={this.state.isVisible}
            >
              <img className="imgHomepage" src="/screen2.png" alt="homepage" />
            </Animated>
          </div>
          <div className={this.state.isVisible ? "textScreen2" : "notDisplay"}>
            <span className="strategija">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-strategija"
                className="caret-right"
              />
              <a href="/usluge/#strategija">{__("STRATEGIJA")}</a> <br />
            </span>

            <span className="kreativni-koncepti">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-kreativni-koncepti"
                className="caret-right"
              />
              <a href="/usluge/#kreativni-koncepti">
                {__("KREATIVNI KONCEPTI")}
              </a>{" "}
              <br />
            </span>

            <span className="event-managment">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-event-managment"
                className="caret-right"
              />
              <a href="/usluge/#event-managment">{__("EVENT MANAGEMENT")}</a>{" "}
              <br />
            </span>

            <span className="graficki-dizajn">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-graficki-dizajn"
                className="caret-right"
              />
              <a href="/usluge/#graficki-i-3d-dizajn">
                {__("GRAFIČKI DIZAJN I 3D DIZAJN")}
              </a>{" "}
              <br />
            </span>

            <span className="graficki-dizajn">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-graficki-dizajn"
                className="caret-right"
              />
              <a href="/usluge/#drustvene-mreze">{__("DRUŠTVENE MREŽE")}</a>{" "}
              <br />
            </span>

            <span className="proizvodnja-pos-pop">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-proizvodnja-pos-pop"
                className="caret-right"
              />
              <a href="/usluge/#proizvodnja-pos-pop">
                {__("PROIZVODNJA POS I POP")} <br />{" "}
                {__("REKLAMNIH MATERIJALA")}
              </a>{" "}
              <br />
            </span>

            <span className="impresivne-postavke">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-impresivne-postavke"
                className="caret-right"
              />
              <a href="/usluge/#impresivne-postavke">
                {__("IMPRESIVNE POSTAVKE")}
              </a>{" "}
              <br />
            </span>

            <span className="stuff-leasing">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-stuff-leasing"
                className="caret-right"
              />
              <a href="/usluge/#stuff-leasing">{__("STUFF LEASING")}</a> <br />
            </span>

            <span className="video-produkcija">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-video-produkcija"
                className="caret-right"
              />
              <a href="/usluge/#video-produkcija">{__("VIDEO PRODUKCIJA")}</a>{" "}
              <br />
            </span>

            <span className="trade-horeca">
              <FontAwesomeIcon
                icon={faCaretRight}
                id="caret-right-trade-horeca"
                className="caret-right"
              />
              <a href="/usluge/#trade-horeca">
                {__("TRADE I HORECA PROMOCIJE")}
              </a>{" "}
              <br />
            </span>
          </div>
        </div>

        <div className="page" id="mobile-middle-page">
          <div className="page-left" id="usluge-phone">
            <div className="page-title">
              <span style={{ color: "#c30c0e", fontSize: "40px" }}>
                {__("OTKLJUČAJTE VRATA")}
              </span>
              <br />
              <span
                id="title-non-bold"
                style={{ color: "#c30c0e", fontSize: "30px" }}
              >
                {__("NAJBOLJIH IDEJA!")}
              </span>
            </div>
            <div className="page-right-mobile">
              <img src="/screen2.png" className="page-img" alt="allInOne" />
            </div>
            <div
              className={this.state.isVisible ? "textScreen2" : "notDisplay"}
            >
              <span className="strategija">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-strategija"
                  className="caret-right"
                />
                <a href="/usluge/#strategija">{__("STRATEGIJA")}</a> <br />
              </span>

              <span className="kreativni-koncepti">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-kreativni-koncepti"
                  className="caret-right"
                />
                <a href="/usluge/#kreativni-koncepti">
                  {__("KREATIVNI KONCEPTI")}
                </a>{" "}
                <br />
              </span>

              <span className="event-managment">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-event-managment"
                  className="caret-right"
                />
                <a href="/usluge/#event-managment">{__("EVENT MANAGEMENT")}</a>{" "}
                <br />
              </span>

              <span className="graficki-dizajn">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-graficki-dizajn"
                  className="caret-right"
                />
                <a href="/usluge/#graficki-i-3d-dizajn">
                  {__("GRAFIČKI DIZAJN I 3D DIZAJN")}
                </a>{" "}
                <br />
              </span>

              <span className="graficki-dizajn">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-graficki-dizajn"
                  className="caret-right"
                />
                <a href="/usluge/#drustvene-mreze">{__("DRUŠTVENE MREŽE")}</a>{" "}
                <br />
              </span>

              <span className="proizvodnja-pos-pop">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-proizvodnja-pos-pop"
                  className="caret-right"
                />
                <a href="/usluge/#proizvodnja-pos-pop">
                  {__("PROIZVODNJA POS I POP")} <br />{" "}
                  {__("REKLAMNIH MATERIJALA")}
                </a>{" "}
                <br />
              </span>

              <span className="impresivne-postavke">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-impresivne-postavke"
                  className="caret-right"
                />
                <a href="/usluge/#impresivne-postavke">
                  {__("IMPRESIVNE POSTAVKE")}
                </a>{" "}
                <br />
              </span>

              <span className="stuff-leasing">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-stuff-leasing"
                  className="caret-right"
                />
                <a href="/usluge/#stuff-leasing">{__("STUFF LEASING")}</a>{" "}
                <br />
              </span>

              <span className="video-produkcija">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-video-produkcija"
                  className="caret-right"
                />
                <a href="/usluge/#video-produkcija">{__("VIDEO PRODUKCIJA")}</a>{" "}
                <br />
              </span>

              <span className="trade-horeca">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  id="caret-right-trade-horeca"
                  className="caret-right"
                />
                <a href="/usluge/#trade-horeca">
                  {__("TRADE I HORECA PROMOCIJE")}
                </a>{" "}
                <br />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Screen2;
