import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./../css/HeaderMobile.css";

class HeaderMobile extends React.Component {
  constructor(init) {
    super(init);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target }) {
    let nb = document.getElementById("header_list_Mobile");
    if (target.checked) {
      nb.style.display = "block";
    } else {
      nb.style.display = "none";
    }
  }

  render() {
    return (
      <div className="headerMobile">
        <div className="fa_bars_Mobile">
          <label htmlFor="checkMobile">
            <input
              type="checkbox"
              id="checkMobile"
              onClick={this.handleChange}
              defaultChecked={this.props.complete}
            />
            <FontAwesomeIcon
              icon={faBars}
              className="fa_bars_icon"
              id="fa_bars_icon"
            />
          </label>

          <div className="header_list_Mobile" id="header_list_Mobile">
            <ul>
              <li>
                {" "}
                <a href="/#o-nama"> O nama </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/#usluge-phone"> Usluge </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/#storytelling"> Storytelling </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/#info"> Info </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/#kontakt-phone"> Kontakt </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/#prijavi-se-phone"> Prijavi se </a>{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="lineMobile">
          <div>
            <img src="/logo_footer.png" alt="logo" className="logo-footer" />
          </div>
          <div className="lineMobile-links">
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://sr-rs.facebook.com/Poliplan1to1/"
              >
                <img src="/facebook.png" alt="facebook" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/poliplan1to1/"
              >
                <img src="/instagram.png" alt="instagram" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/poliplan-1to1/"
              >
                <img src="/linkedin.png" alt="linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderMobile;
