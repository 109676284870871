import React from "react";
import "./../../css/General.css";
import SliderServices from "./SliderServices";

const images = [
  "/EventManagment/Event-Managment-01.jpg",
  "/EventManagment/Event-Managment-02.jpg",
  "/EventManagment/Event-Managment-03.jpg",
  "/EventManagment/Event-Managment-04.jpg",
  "/EventManagment/Event-Managment-05.jpg",
  "/EventManagment/Event-Managment-06.jpg",
  "/EventManagment/Event-Managment-07.jpg",
];

class EventManagment extends React.Component {
  render() {
    return (
      <div className="page" id="event-managment">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">EVENT</span>
            <br />
            <span>MANAGMENT</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
          <div className="page-text">
            <span className="red-italic-text">
              „Ne planirati je isto kao planirati neuspeh.“
            </span>
          </div>
          <div className="page-space"></div>

          <div className="page-text">
            Ne morate nam reći svoje želje da bismo ih mi ispunili. Bilo da je
            reč o korporativnim događajima, festivalima, koncertima,
            konferencijama ili sajmovima analiziraćemo vaš brend, ciljnu grupu,
            i dajući na značaju i najsitnijem detalju stvorićemo događaj za
            pamćenje.
          </div>
          <div className="page-text">
            Sve od ideje i kreativnog koncepta, do produkcije materijala,
            tehničke, logističke i promotivne podrške – sve je naša briga.
          </div>
        </div>

        <div className="page-right">
          <div className="page-slide">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EventManagment;
