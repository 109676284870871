import React from "react";
import "./../../css/Screen5.css";

class Screen5 extends React.Component {
  constructor(init) {
    super(init);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      file: null,
    };
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleFile = this.handleFile.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const position = document.getElementById("postani-deo-tima");
    const blackLogo = document.getElementById("logo-homepage");

    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < position.offsetTop - 100;
      if (isTop !== true && window.innerWidth >= 650) {
        blackLogo.style.display = "block";
        this.setState({ isVisible: true });
      }
    });
  }

  handleName(name) {
    this.setState({
      name: name.target.value,
    });
  }

  handleEmail(email) {
    this.setState({
      email: email.target.value,
    });
  }

  handlePhone(phone) {
    this.setState({
      phone: phone.target.value,
    });
  }

  handleMessage(message) {
    this.setState({
      message: message.target.value,
    });
  }

  handleFile(event) {
    this.setState({
      file: event.target.files[0],
    });
  }

  handleSubmit() {
    console.log(this.state);
  }

  render() {
    return (
      <div>
        <div className="poliplan-prijavi-se" id="postani-deo-tima">
          <img
            className="img-poliplan-prijavi-se"
            src="/pozadinaPrijaviSe.jpeg"
            alt="prijaviSe"
          />
          <div className="text-prijavi-se">
            <div className="prijavi-se-naslov">
              <span id="prijavi-se">POSTANI DEO</span>
              <br />
              <span>
                POLIPLAN <span id="poliplan-1to1">1TO1</span>
              </span>
              <br />
              <div
                id="prijavi-se"
                style={{
                  textAlign: "right",
                  paddingTop: "2px",
                  marginRight: "-2%",
                }}
              >
                TIMA
              </div>
            </div>
            <div className="prijavi-se-formular">
              <div className="row-prijavi-se">
                <div className="form-group">
                  <label htmlFor="name">IME I PREZIME</label> <br />
                  <input
                    type="text"
                    value={this.state.name}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="name"
                    onChange={this.handleName}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">E-MAIL</label> <br />
                  <input
                    type="text"
                    value={this.state.email}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="email"
                    onChange={this.handleEmail}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phone">KONTAKT TELEFON</label> <br />
                  <input
                    type="text"
                    value={this.state.phone}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="phone"
                    onChange={this.handlePhone}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="file">
                    Dokument <span id="prijavi-se-text-file">(do 25MB)</span>
                  </label>{" "}
                  <br />
                  <input
                    type="file"
                    className="prijavi-se-form"
                    name="file"
                    onChange={this.handleFile}
                  />
                </div>
              </div>
              <div>
                <div className="form-group">
                  <label htmlFor="message">VAŠA PORUKA</label> <br />
                  <textarea
                    type="text"
                    rows="4"
                    cols="30"
                    value={this.state.message}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="message"
                    onChange={this.handleMessage}
                  />
                </div>

                <div className="form-group">
                  <button
                    className="prijavi-se-button"
                    onClick={this.handleSubmit}
                  >
                    PRIJAVI SE!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="page"
          id="mobile-middle-page"
          style={{ paddingBottom: "100px" }}
        >
          <div className="page-left" id="prijavi-se-phone">
            <div className="page-title" style={{ fontSize: "39px" }}>
              <span id="title-non-bold">POSTANI DEO</span>
              <br />
              <span>
                POLIPLAN <span id="poliplan-1to1">1TO1</span>
              </span>
              <br />
              <span id="title-non-bold">TIMA</span>
            </div>
            <div className="page-right-mobile">
              <img
                src="/pozadinaPrijaviSe.jpeg"
                className="page-img"
                alt="allInOne"
              />
            </div>
            <div className="prijavi-se-formular">
              <div className="row-prijavi-se">
                <div className="form-group">
                  <label htmlFor="name">IME I PREZIME</label> <br />
                  <input
                    type="text"
                    value={this.state.name}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="name"
                    onChange={this.handleName}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">E-MAIL</label> <br />
                  <input
                    type="text"
                    value={this.state.email}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="email"
                    onChange={this.handleEmail}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phone">KONTAKT TELEFON</label> <br />
                  <input
                    type="text"
                    value={this.state.phone}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="phone"
                    onChange={this.handlePhone}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="file">
                    Dokument <span id="prijavi-se-text-file">(do 25MB)</span>
                  </label>{" "}
                  <br />
                  <input
                    type="file"
                    className="prijavi-se-form"
                    name="file"
                    onChange={this.handleFile}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">VAŠA PORUKA</label> <br />
                  <textarea
                    type="text"
                    rows="4"
                    cols="30"
                    value={this.state.message}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="message"
                    onChange={this.handleMessage}
                  />
                </div>

                <div className="form-group">
                  <button
                    className="prijavi-se-button"
                    onClick={this.handleSubmit}
                  >
                    PRIJAVI SE!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Screen5;
