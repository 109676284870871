import React from "react";
import "./../../css/Screen4.css";
import "./../../css/General.css";

class Screen4 extends React.Component {
  componentDidMount() {
    const position = document.getElementById("kontakt");
    const blackLogo = document.getElementById("logo-homepage");

    window.addEventListener("scroll", () => {
      const isTop = window.scrollY > position.offsetT + 100;
      if (isTop !== true && window.innerWidth >= 650) {
        blackLogo.style.display = "block";
        this.setState({ isVisible: true });
      }
    });
  }
  render() {
    return (
      <div className="page" id="info">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">INFO</span>
            <br />
            <span>NAVIGACIJA</span>
          </div>
          <div className="page-right-mobile">
            <iframe
              className="map"
              src="https://www.google.com/maps/d/embed?mid=1ijX1nfJWn4mZCWxuioaEOiF-aZ3w3l_y&hl=sr"
              width="100%"
              height="95%"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="mapa"
            />
          </div>
          <div className="poliplan-info-tekst">
            <div className="poliplan-info-tekst-bold">LOKACIJE</div>
            <div className="poliplan-info-tekst-detalji">
              Majora Branka Vukosavljevića 72, <br />
              11000 Beograd, Srbija
            </div>
            <div className="poliplan-info-tekst-detalji">
              Dr Teodora Borockog 14, <br />
              11000 Beograd, Srbija
            </div>
            <div className="poliplan-info-tekst-detalji">
              Hemingvejeva 2a, <br />
              11000 Beograd, Srbija
            </div>
            <div className="poliplan-info-tekst-bold">RADNO VREME</div>
            <div className="poliplan-info-tekst-detalji">
              Ponedeljak-Petak: 09:00-17:00 <br />
              Subota: Ne radimo <br />
              Nedelja: Ne radimo
            </div>
            <div
              className="poliplan-info-tekst-detalji"
              id="poliplan-info-email"
            >
              E-mail: office@poliplan1to1.rs
            </div>
          </div>
        </div>

        <div className="page-right">
          <iframe
            className="map"
            src="https://www.google.com/maps/d/embed?mid=1ijX1nfJWn4mZCWxuioaEOiF-aZ3w3l_y&hl=sr"
            width="100%"
            height="95%"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="mapa"
          />
        </div>
      </div>
    );
  }
}

export default Screen4;
