import React from "react";
import "./../../css/General.css";
import SliderServices from "./SliderServices";

const images = [
  "/GrafickiI3dDizajn/3d-i-graficki.png",
  "/GrafickiI3dDizajn/3d-i-graficki-02.png",
  "/GrafickiI3dDizajn/3d-i-graficki-03.png",
  "/GrafickiI3dDizajn/graficki-01.png",
  "/GrafickiI3dDizajn/graficki-02.png",
  "/GrafickiI3dDizajn/graficki-03.png",
  "/GrafickiI3dDizajn/graficki-04.png",
  "/GrafickiI3dDizajn/graficki-05.png",
];

class GrafickiDizajn extends React.Component {
  render() {
    return (
      <div className="page" id="graficki-i-3d-dizajn">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">GRAFIČKI I</span>
            <br />
            <span>3D DIZAJN</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="blackDots"
            />
          </div>
          <div className="page-text">
            <span className="red-italic-text">
              „Da li je kreativnost nastala iz radoznalosti?“
            </span>
          </div>
          <div className="page-space"></div>

          <div className="page-text">
            Kreativnost je klizna skala od očiglednog do neobičnog. Ukoliko
            stremite originalnosti, nemate granice ispred sebe, prođite zajedno
            sa nama kroz magični portal koji povezuje naš tim i vaš brend.
          </div>
          <div className="page-text" id="page-text-not-display">
            Pokrenite evoluciju kreativne misli kroz:{" "}
            <span className="bold-word" style={{ fontSize: "16px" }}>
              Grafički i industrijski dizajn, 3D modelovanje, POSM , Concept
              dizajn, Digitalnu fotografiju, Dizajn pakovanja, Vizuelni
              identitet, Dizajn publikacija i Korporativni identitet.
            </span>
          </div>
        </div>

        <div className="page-right">
          <div className="page-slide">
            <SliderServices
              images={images}
              arrow="blackArrow"
              dots="blackDots"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GrafickiDizajn;
