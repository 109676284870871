import React from "react";
import { Slide } from "react-slideshow-image";
import "../../css/slideServices.css";
import "react-slideshow-image/dist/styles.css";
import { NextArrow, PrevArrow } from "./SliderArrows";

const SliderServices = (props) => {
  return (
    <div className="slide-container">
      <Slide
        className={"slideServices " + props.arrow + " " + props.dots}
        indicators={true}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
      >
        {props.images.map((image, index) => (
          <div key={index} className="each-slide">
            <img src={image} className="page-img" alt="allInOne" />
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default SliderServices;
