import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

export class PrevArrow extends React.Component {
  render() {
    return (
      <button {...this.props} className="slide-arrow slide-prew">
        <FontAwesomeIcon icon={faAngleLeft} size="3x" />
      </button>
    );
  }
}

export class NextArrow extends React.Component {
  render() {
    return (
      <button {...this.props} className="slide-arrow slide-next">
        <FontAwesomeIcon icon={faAngleRight} size="3x" />
      </button>
    );
  }
}
