import React from "react";
import "./../../css/Screen6.css";

class Screen6 extends React.Component {
  constructor(init) {
    super(init);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      isVisible: false,
    };
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const position = document.getElementById("kontakt");
    const blackLogo = document.getElementById("logo-homepage");

    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < position.offsetTop - 200;
      if (isTop !== true) {
        blackLogo.style.display = "none";
        this.setState({ isVisible: true });
      } else {
        blackLogo.style.display = "block";
      }
    });
  }

  handleName(name) {
    this.setState({
      name: name.target.value,
    });
  }

  handleEmail(email) {
    this.setState({
      email: email.target.value,
    });
  }

  handlePhone(phone) {
    this.setState({
      phone: phone.target.value,
    });
  }

  handleMessage(message) {
    this.setState({
      message: message.target.value,
    });
  }

  handleSubmit() {
    console.log(this.state);
  }

  render() {
    return (
      <div>
        <div className="poliplan-prijavi-se" id="kontakt">
          <a href="/">
            <img
              className={this.state.isVisible ? "logo-on-page" : "notDisplay"}
              src="/whiteLogo.png"
              alt="logo"
            />
          </a>
          <div
            className="hr-line"
            style={{
              borderBottom: "2px solid #FFFFFF",
              borderLeft: "2px solid #FFFFFF",
              width: "54%",
              zIndex: "200",
            }}
          ></div>
          <div
            className="textONama"
            style={{
              fontWeight: "normal",
              zIndex: "1000",
              color: "#FFFFFF",
              letterSpacing: "0",
              right: "4%",
            }}
          >
            Kontakt
          </div>
          <img
            className="img-poliplan-prijavi-se"
            src="/Kontakt/kontakt.jpg"
            alt="kontakt"
          />
          <div className="text-prijavi-se">
            <div className="prijavi-se-naslov">
              <span id="kontakt" style={{ color: "white" }}>
                KONTAKT
              </span>
              <br />
              <span style={{ color: "white" }}>
                POLIPLAN <span id="poliplan-1to1">1TO1</span>
              </span>
            </div>
            <div className="prijavi-se-formular" style={{ marginTop: "100px" }}>
              <div className="row-prijavi-se">
                <div className="form-group">
                  <label style={{ color: "white" }} htmlFor="email">
                    E-MAIL
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    value={this.state.email}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="email"
                    onChange={this.handleEmail}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="message" style={{ color: "white" }}>
                    PORUKA
                  </label>{" "}
                  <br />
                  <textarea
                    type="text"
                    rows="4"
                    cols="30"
                    value={this.state.message}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="message"
                    onChange={this.handleMessage}
                  />
                </div>
                <div className="form-group">
                  <button
                    className="prijavi-se-button"
                    onClick={this.handleSubmit}
                  >
                    POŠALJI
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="page"
          id="mobile-middle-page"
          style={{ paddingBottom: "10px" }}
        >
          <div className="page-left" id="kontakt-phone">
            <div className="page-title" style={{ fontSize: "39px" }}>
              <span id="title-non-bold">KONTAKT</span>
              <br />
              <span>
                POLIPLAN <span id="poliplan-1to1">1TO1</span>
              </span>
            </div>
            <div className="page-right-mobile">
              <img
                src="/Kontakt/kontakt.jpg"
                className="page-img"
                alt="allInOne"
              />
            </div>
            <div className="prijavi-se-formular" style={{ marginTop: "20px" }}>
              <div className="row-prijavi-se">
                <div className="form-group">
                  <label style={{ color: "black" }} htmlFor="email">
                    E-MAIL
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    value={this.state.email}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="email"
                    onChange={this.handleEmail}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="message" style={{ color: "black" }}>
                    PORUKA
                  </label>{" "}
                  <br />
                  <textarea
                    type="text"
                    rows="4"
                    cols="30"
                    value={this.state.message}
                    className="prijavi-se-form"
                    autoComplete="off"
                    name="message"
                    onChange={this.handleMessage}
                  />
                </div>
                <div className="form-group">
                  <button
                    className="prijavi-se-button"
                    onClick={this.handleSubmit}
                  >
                    POŠALJI
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Screen6;
