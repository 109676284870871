import React from "react";
import Slide from "./../Slide";
import "./../../css/General.css";
import HeaderMobile from "../HeaderMobile";
import Header from "../Header";

class NoveTehnologije extends React.Component {
  render() {
    return (
      <div>
        <HeaderMobile />
        <Header />
        <a href="/">
          <img className="logoScroll" src="/logo.png" alt="logo" />
        </a>
        <div className="page" id="nove-tehnologije">
          <div className="page-left">
            <div className="page-title">
              <span id="title-non-bold">NOVE</span>
              <br />
              <span>TEHNOLOGIJE</span>
            </div>
            <div className="page-right-mobile">
              <img
                src="/NoveTehnologije/01.jpg"
                className="page-img"
                alt="allInOne"
              />
            </div>
            <div className="page-text">
              Ni nakon 15 godina poslovanja ne prestajemo da se usavršavamo i
              ulažemo u proširenje kapaciteta u svim segmentima poslovanja. Još
              jedan laser za sečenje i gravuru postao je stalan član našeg tima
              za proizvodnju reklamnog materijala.
            </div>
            <div className="page-text">
              Zahvaljujući snažnoj, pouzdanoj i tehnološki naprednoj opremi u
              koju ulažemo, pružamo vam maksimalnu preciznost i finoću prilikom
              iscrtavanja složenih oblika, kontura i linija na materijalu po
              želji. WidLaser je snažni laser koji omogućava prorezivanje i
              gravuru različitih materijala.
            </div>
            <div className="page-text" style={{ paddingBottom: "100px" }}>
              Gravuru je nemoguće skinuti ili oštetiti, otporna je na
              hemikalije, UV zračenje i ogrebotine.{" "}
              <span className="red-bold-text">#ALLINONE</span>
            </div>
          </div>

          <div className="page-right">
            <div className="page-slide">
              <Slide
                sliderName="radio-btn_5"
                sliderId={["radio1_5", "radio2_5", "radio3_5", "radio4_5"]}
                sliderNumber="_5"
                rbSlider="2"
                images={[
                  "/NoveTehnologije/01.jpg",
                  "/NoveTehnologije/02.jpg",
                  "/NoveTehnologije/03.jpg",
                  "/NoveTehnologije/05.jpg",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NoveTehnologije;
