import React from "react";
import "./../../css/General.css";
import SliderServices from "./SliderServices";

const images = ["/Stuff/Stuff2.jpg", "/Stuff/Stuff1.jpg"];

class StuffLeasing extends React.Component {
  render() {
    return (
      <div className="page" id="stuff-leasing">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">STUFF</span>
            <br />
            <span>LEASING</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
          <br />
          <br />
          <div className="page-text">
            <span className="red-bold-text">
              Potreban vam je odgovoran autsorsing?
            </span>
          </div>
          <div className="page-text">
            Raspolažemo sa preko 100 zaposlenih na teritoriji cele Srbije koji
            se bave prodajom, komercijalnim poslovima i merčendajzingom. <br />
            <br />
            Obezbeđujemo punu i odgovornu:
            <ul
              id="ul-stuff-leasing"
              style={{ listStyleImage: "url(/1to1_mini.png)" }}
            >
              <li>HR</li>
              <li>administrativnu i</li>
              <li>terensku podršku</li>
            </ul>
          </div>
        </div>

        <div className="page-right">
          <div className="page-slide">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="whiteDots"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default StuffLeasing;
