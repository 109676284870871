import React from "react";
import { __ } from "./../../utils/i18n";
import "./../../css/Screen3.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

class Screen3 extends React.Component {
  constructor(init) {
    super(init);
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    if (window.innerWidth > 650) {
      const position = document.getElementById("storytelling");
      window.addEventListener("scroll", () => {
        const isTop = window.scrollY < position.offsetTop - 100;
        if (isTop !== true) {
          this.setState({ isVisible: true });
        }
      });
    } else {
      this.setState({ isVisible: true });
    }
  }

  render() {
    return (
      <div>
        <div className="screen3" id="storytelling">
          <div
            className={this.state.isVisible ? "hr-line" : "notDisplay"}
          ></div>
          <div className="textONama">Storytelling</div>
          <div className="left-screen3">
            <div
              className={this.state.isVisible ? "title-screen3" : "notDisplay"}
            >
              <span className="no-bold-text" id="nove-tehnologije-title">
                {__("NOVE TEHNOLOGIJE")}
              </span>{" "}
              <br />
              <span className="bold-text" style={{ color: "#c30c0e" }}>
                {__("U POLIPLANU!")}
              </span>
            </div>

            <div
              className={this.state.isVisible ? "images-screen3" : "notDisplay"}
            >
              <img src="/NoveTehnologije/05.jpg" id="image-0" alt="" />
            </div>
            <div
              className={this.state.isVisible ? "text-screen3" : "notDisplay"}
            >
              Ni nakon 15 godina poslovanja ne prestajemo da se usavršavamo i
              ulažemo u proširenje kapaciteta u svim segmentima poslovanja. Još
              jedan laser za sečenje i gravuru postao je stalan član našeg tima
              za proizvodnju reklamnog materijala.
              <span className="button-screen3" id="nove-tehnologije">
                <a href="noveTehnologije">
                  <button className="button-tema">
                    PROČITAJ
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      className="caret-right-button-screen3"
                    />
                  </button>
                </a>
              </span>
            </div>
          </div>
          <div className="right-screen3">
            <div className="img-right-screen3">
              <div className="row-screen3">
                <img src="/Intervju/NikolaVitasIntervju.jpg" alt="intervju" />
                <div
                  className={this.state.isVisible ? "text-span" : "notDisplay"}
                >
                  <span id="red-text">Konceptualni dizajner</span>
                  <br /> je tu da spoji sve što vam se u prvi mah učini
                  nemogućim. Zapravo, konceptualni dizajner je tu da nešto što
                  vi ni ne možete da zamislite on vidi i izdizajnira, doda svoj
                  talenat i znanje, a nekad i da vam pročita misli.
                  <div className="button-screen3">
                    <a href="intervju">
                      <button className="button-tema">
                        INTERVJU
                        <FontAwesomeIcon
                          icon={faCaretRight}
                          className="caret-right-button-screen3"
                        />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="img-right-screen3">
              <div className="row-screen3">
                <img src="/Storytelling/3.png" alt="intervju" />
                <div
                  className={this.state.isVisible ? "text-span" : "notDisplay"}
                >
                  <span id="red-text">
                    Kako da kreiraš
                    <br /> oglas koji prodaje?{" "}
                  </span>
                  <br />
                  Nekada je bilo dovoljno da otvorite svoju Facebook/Instagram
                  stranicu i mogli ste uz relativno mali budžet, a vrlo često i
                  potpuno organski da postignete dobre rezultate. Danas, bez
                  oglašavanja nema rezultata. Kako onda najbolje da iskoristite
                  budžet koji imate?
                  <div className="button-screen3">
                    <a href="blog">
                      <button className="button-tema">
                        PROČITAJ
                        <FontAwesomeIcon
                          icon={faCaretRight}
                          className="caret-right-button-screen3"
                        />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Screen3;
