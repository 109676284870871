import React from "react";
import "./../../css/General.css";
import "./../../css/Blog.css";
import HeaderMobile from "../HeaderMobile";
import Header from "../Header";
import SliderServices from "../services/SliderServices";

const images = [
  "/Blog/1.png",
  "/Blog/2.png",
  "/Blog/3.png",
  "/Blog/4.png",
  "/Blog/5.png",
  "/Blog/6.png",
];

class Blog extends React.Component {
  render() {
    return (
      <div>
        <HeaderMobile />
        <Header />
        <a href="/">
          <img className="logoScroll" src="/logo.png" alt="logo" />
        </a>
        <div className="page" id="blog">
          <div className="page-left">
            <div className="page-title" id="page-title-blog">
              <span id="title-non-bold-blog">Facebook oglašavanje: </span>
              <br />
              <span>Kako da napišeš oglas koji prodaje?</span>
            </div>
            <div className="page-right-mobile">
              <img src="/Blog/1.png" className="page-img" alt="allInOne" />
            </div>
            <div className="page-text">
              Do pre oko 7 godina bilo je dovoljno da otvorite svoju Facebook
              stranicu i mogli ste uz relativno mali budžet, a vrlo često i
              potpuno organski (bez novca), da postignete dobre rezultate.
              Danas, Facebook oglašavanje usavršeno je do tančina, a organski ne
              možete uraditi ništa. Dakle, bez oglašavanja nema rezultata. Kako
              onda najbolje da iskoristite budžet koji imate? Tako što ćete
              kreirati <span className="bold-word">oglas koji prodaje</span>!
            </div>
            <div className="page-text">
              <span className="red-bold-text">
                1. Neka tvoj oglas izazove „palac stoper“ efekat!
              </span>
              <br />
              <br />
              Verovatno ni sami ne znate koliko ste reklama na Instagramu ili
              Facebooku videli juče. To je zato što ih niste primetili.
              Skrolovali ste kroz feed ili gledali storije, ali niste se ni na
              jednoj zaustavili. Ovo je prva stvar koju vaš oglas mora da
              postigne. Kako?
              <br />
              <br />
              Bacite mamac i nemojte se bojati. Imate manje od 2 sekunde da
              naterate osobu da se zaustavi na vašem oglasu, bilo da je reč o
              video ili foto formatu. Da biste bacili adekvatan mamac morate
              definisati profil vašeg kupca.
              <br />
              <br />
              <span className="bold-word">
                Ko je on? Koliko ima godina? Šta ga muči? <br />
                Gde radi? Šta ga čini srećnim?
              </span>
              <br />
              <br />
              Pretpostavimo da su nam ciljna grupa roditelji dece od 2-7 godina,
              a da vi prodajete bojanke za zidove i želite da napravite oglas za
              njihovu prodaju na Facebooku i Instagramu.
              <br />
              <br />
              Kako <span className="bold-word">ne treba</span> da izgleda palac
              stoper naslov za vaš oglas? <br />
              „Naše bojanke za zidove će oduševiti vaše mališane, najbolje su,
              imamo ih u varijantama svih omiljenih crtanih junaka!“ Bla bla ...
              <br />
              <br />
              Kako <span className="bold-word">treba</span> da izgleda palac
              stoper vaše reklame? <br />
              „Vaša deca vole da šaraju zidove? Pustite ih, sada imamo rešenje i
              za vas, a i za njih!“
              <br />
              <br />
              Zašto baš ovako? Vaša ciljna grupa su roditelji, a ne deca.
              Potrebno je da rešite problem koji imaju, a to su deca koja
              dohvate bojice i šaraju zidove, a oni ih jure, grde, i ne mogu da
              nađu način kako da ih spreče. U ovoj rečenici se nalazi razlog
              zašto bi neko želeo da kupi vaš proizvod. Istovremeno, morate
              zainteresovati roditelja da nastavi da čita, zato u prvoj rečenici
              nećete odati koje je rešenje. Tek sledeći korak je{" "}
              <span className="bold-word">ponuda</span>.
            </div>
            <div className="page-text">
              <span className="red-bold-text">
                2. Objasnite kupcu šta nudite
              </span>
              <br />
              <br />
              Kada ste zainteresovali kupca za vaš proizvod nemojte da ga
              ostavite bez objašnjenja koje rešenje mu nudite. Zapamtite, i
              dalje je fokus na osobi koja čita, a ne na vašem proizvodu.
              Nemojte ga hvaliti u nedogled, nego napišite benefite bojanke
              onako kako biste ih ispričali nekoj mami koju poznajete, a koja
              često pokušava da spase svoje zidove od šaranja.
              <br />
              <br />
              Zamislite da ste je pozvali na kafu i rekli:
              <br />
              <br />
              „Moje bojanke su napravljene od najkvalitetnijeg papira, tvoja
              deca će uživati u svakom trenutku bojenja, opčinjeni njihovim
              omiljenim crtanim junacima.“ Verovatno će krenuti da se smeje i
              pitati vas zašto tako čudno pričate?
              <br />
              <br />
              Tako da, ovo je pogrešan nastavak. Ponuda mora da bude jasna i
              konkretna.
              <br />
              <br />
              „Stigle su bojanke za zidove! Možemo ih izraditi u svim veličinama
              i oblicima. Zalepite ih na zid jednostavno, pustite decu da se
              izigraju, a vi uživajte. Njihovo odlepljivanje neće ostaviti
              nikakve fleke, dezen birate sami, a u zavisnosti od toga šta vaši
              najmlađi vole radimo i personalizovanu štampu. Dostava na
              teritoriji Srbije je besplatna.“
              <br />
              <br />
              Sve smo rekli, šta dalje?
            </div>
            <div className="page-text" style={{ paddingBottom: "100px" }}>
              <span className="red-bold-text">3. Poziv na akciju</span>
              <br />
              <br />
              Ukoliko su vam cilj porudžbine, u reklamu morate implementirati
              zaključak u vidu poziva na akciju. U zavisnosti od mesta na kojem
              vaši kupci mogu poručiti proizvod (sajt/facebook/instagram),
              napisaćete da mogu poručiti odmah, uz naglasak da će im porudžbina
              stići brzo i jednostavno. Uvek dobro prolazi i ako kreirate efekat
              ograničene ponude, pa možete napisati: „Poručite odmah i na poklon
              dobijate paket od 20 bojica!“
              <br />
              <br />
              <br />
              <span className="red-bold-text"> 4. Vizual</span>
              <br />
              <br />
              Šta mora da ispuni vizual vaše reklame?
              <ul>
                <li> Mora da privuče pažnju</li>
                <li> Mora da prikaže proizvod</li>
                <li>
                  Mora da bude konzistentan sa vizuelnim identitetom vašeg
                  brenda
                </li>
              </ul>
              Facebook i Instagram imaju različite „favorite“ kada je reč
              fotografijama. Na Facebooku bolje prolaze vizuali koji se ne
              stapaju sa početnom stranom, pa ćete tako česće viđati reklame sa
              vizualima jarkih nijansi. Instagram sa druge strane preferira da
              se uklopite u njegov stil – birajte boje koje prijaju oku i
              instagramične slike.
              <br />
              <br />
              Kada kreirate reklamu jasno prikažite proizvod koji prodajete i
              budite autentični. Uvek ispoštujte vizuelni identitet vašeg brenda
              – implementirajte vaše boje, vaš font, stil, logo i poruke.
              <br />
              <br />
              Ukoliko ispoštujete sve navedene korake, uz odabir prave kampanje
              rezultati sigurno neće izostati. :)
              <br />
              <br />
              <span className="red-bold-text">Nevena Tomić </span> <br />
              <span style={{ color: "#c30c0e" }}>
                {" "}
                Digital Communications Manager{" "}
              </span>
            </div>
            <div className="nothing"></div>
          </div>

          <div className="page-right" id="page-right-blog">
            <div className="page-slide">
              <SliderServices
                images={images}
                arrow="whiteArrow"
                dots="whiteDots"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
