import React from "react";
import "./../../css/General.css";
import SliderServices from "./SliderServices";

const images = [
  "/ProizvodnjaPosIPop/pos1.png",
  "/ProizvodnjaPosIPop/pos2.png",
  "/ProizvodnjaPosIPop/pos3.png",
  "/ProizvodnjaPosIPop/pos4.png",
];

class ProizvodnjaPosIPop extends React.Component {
  render() {
    return (
      <div className="page" id="proizvodnja-pos-pop">
        <div className="page-left">
          <div className="page-title">
            <span id="title-non-bold">PROIZVODNJA</span>
            <br />
            <span>POS I POP</span>
          </div>
          <div className="page-right-mobile">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="blackDots"
            />
          </div>
          <div className="page-text">
            <span className="red-italic-text">
              „Ulaganje truda u stvaranje nečega donosi zadovoljstvo. A
              zadovoljstvo vam daje motivaciju da stvarate više.“
            </span>
          </div>
          <div className="page-text" id="page-text-not-display">
            Kako da dođete do originalne i vredne ideje? Nudimo vam ključ koji
            može da otključa sva naša vrata i predstavi kreativnost i
            inovativnost.
          </div>
          <div className="page-text">
            Naš produkcijski tim priča priču kroz različite segmente: <br />
            istraživanje / dizajn / razvoj / izradu prototipa / produkciju /
            testiranje / transport / montažu / održavanje / demontažu /
            skladištenje.
          </div>
          <div className="page-text">
            Nudimo vam da i vi ispričate vašu priču potrošaču kroz: <br />
            Spoljašnje brendiranje / Unutrašnje brendiranje / Produkciju
            scenografije / Brendiranje vozila / POSM.
          </div>
        </div>

        <div className="page-right">
          <div className="page-slide">
            <SliderServices
              images={images}
              arrow="whiteArrow"
              dots="blackDots"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProizvodnjaPosIPop;
