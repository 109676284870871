import React from "react";
import "./../css/General.css";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";

class NasNacin extends React.Component {
  render() {
    return (
      <div>
        <HeaderMobile />
        <Header />
        <a href="/">
          <img className="logoScroll" src="/logo.png" alt="logo" />
        </a>
        <div className="page" id="strategija">
          <div className="page-left">
            <div className="page-title">
              <span id="title-non-bold">NAŠ</span>
              <br />
              <span>NAČIN</span>
            </div>
            <div className="page-right-mobile">
              <img src="/NasNacin/1.png" className="page-img" alt="allInOne" />
            </div>
            <div className="page-text">
              <span className="red-bold-text">
                Partner / Brief / Jel ste videli mejl? / Šta nam sve treba? /
                Kreativno rešenje/ Požuri! / Dizajn / Poštovani/dragi poslato /
                Odobreno / Kreni! / Produkcija / Događaj / Uspeh! / Izveštaj /
                Lekcija!....MI!
              </span>
            </div>
            <div className="page-text">
              Koliko god da su kratki rokovi i zadaci izazovni, uvek nalazimo
              pravi način da od briefa do uspeha dođemo uz osmeh, kreativnost,
              zabavu i kvalitet.
            </div>
            <div className="page-text" style={{ paddingBottom: "100px" }}>
              Cenimo vaše vreme i dobro koristimo naše. Uvek smo u niskom
              startu, posvećeni i spremni da kreiramo najbolje rešenje!
            </div>
          </div>

          <div className="page-right">
            <img src="/NasNacin/1.png" className="page-img" alt="strategija" />
          </div>
        </div>
      </div>
    );
  }
}

export default NasNacin;
