import React, { useState, useEffect } from "react";
import "../css/PoliplanInNumbers.css";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";

function PoliplanInNumbers() {
  const [isSubotica, setIsSubotica] = useState(false);
  const [isNoviSad, setIsNoviSad] = useState(false);
  const [isBeograd, setIsBeograd] = useState(false);
  const [isSabac, setIsSabac] = useState(false);
  const [isUzice, setIsUzice] = useState(false);
  const [isCacak, setIsCacak] = useState(false);
  const [isKragujevac, setIsKragujevac] = useState(false);
  const [isKraljevo, setIsKraljevo] = useState(false);
  const [isNis, setIsNis] = useState(false);
  const [isCrnaGora, setIsCrnaGora] = useState(false);
  const [isMakedonija, setIsMakedonija] = useState(false);

  const [okvir, setOkvir] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setOkvir(!okvir);
    }, 700);
    return () => clearInterval(interval);
  }, [okvir]);

  console.log(okvir);

  return (
    <div>
      <Header />
      <HeaderMobile />
      <div className="poliplan_in_numbers_img">
        <img src="/Poliplan_in_numbers.jpg" alt="map" />
      </div>
      <div className="poliplan_in_numbers_mobile">
        <img src="/mapaTelefon.jpg" alt="map" />
      </div>
      <a className="poliplan_in_numbers_logo" href="/">
        <img src="/logo.png" alt="logo" />
      </a>
      <div>
        <button
          className={"gradovi " + (okvir ? "subotica_okvir" : "subotica")}
          onMouseEnter={() => setIsSubotica(true)}
          onMouseLeave={() => setIsSubotica(false)}
        />
        <button
          className={"gradovi " + (okvir ? "novi_sad_okvir" : "novi_sad")}
          onMouseEnter={() => setIsNoviSad(true)}
          onMouseLeave={() => setIsNoviSad(false)}
        />
        <button
          className="gradovi beograd"
          onMouseEnter={() => setIsBeograd(true)}
          onMouseLeave={() => setIsBeograd(false)}
        />
        <button
          className={"gradovi " + (okvir ? "sabac_okvir" : "sabac")}
          onMouseEnter={() => setIsSabac(true)}
          onMouseLeave={() => setIsSabac(false)}
        />
        <button
          className={"gradovi " + (okvir ? "uzice_okvir" : "uzice")}
          onMouseEnter={() => setIsUzice(true)}
          onMouseLeave={() => setIsUzice(false)}
        />
        <button
          className={"gradovi " + (okvir ? "cacak_okvir" : "cacak")}
          onMouseEnter={() => setIsCacak(true)}
          onMouseLeave={() => setIsCacak(false)}
        />
        <button
          className={"gradovi " + (okvir ? "kragujevac_okvir" : "kragujevac")}
          onMouseEnter={() => setIsKragujevac(true)}
          onMouseLeave={() => setIsKragujevac(false)}
        />
        <button
          className={"gradovi " + (okvir ? "kraljevo_okvir" : "kraljevo")}
          onMouseEnter={() => setIsKraljevo(true)}
          onMouseLeave={() => setIsKraljevo(false)}
        />
        <button
          className={"gradovi " + (okvir ? "nis_okvir" : "nis")}
          onMouseEnter={() => setIsNis(true)}
          onMouseLeave={() => setIsNis(false)}
        />
        <button
          className={"gradovi " + (okvir ? "crna_gora_okvir" : "crna_gora")}
          onMouseEnter={() => setIsCrnaGora(true)}
          onMouseLeave={() => setIsCrnaGora(false)}
        />
        <button
          className={"gradovi " + (okvir ? "makedonija_okvir" : "makedonija")}
          onMouseEnter={() => setIsMakedonija(true)}
          onMouseLeave={() => setIsMakedonija(false)}
        />
      </div>
      <div>
        {isBeograd && (
          <div className="hover beograd_hover">
            <div className="beograd_naslov">
              <span className="red">SEDIŠTE KOMPANIJE</span>
            </div>
            <div className="beograd_tekst">
              KANCELARIJSKI PROSTOR -{" "}
              <span className="red">
                400m
                <sup>2</sup>
              </span>
              <br />
              MAGACINI -{" "}
              <span className="red">
                700 m<sup>2</sup>
              </span>
              <br />
              DIZAJNERA - <span className="red">6</span>
              <br />
              PROMOTERA - <span className="red">900</span>
              <br />
              PROMO LIDERA - <span className="red">80</span>
              <br />
              CENTAR ZA OBUKU
              <br />
              TRANSPORTNIH SREDSTAVA - <span className="red">5</span>
              <br />
              ŠTAMPA I PRODUKCIJA
            </div>
          </div>
        )}
        {isBeograd && (
          <div className="hover border red right beograd_right_hover">
            KANCELARIJSKI PROSTOR
            <br />
            MAGACINISKI PROSTOR
          </div>
        )}
        {isSubotica && (
          <div className="hover border right subotica_hover">
            REGIONALNI MENADŽER - <span className="red">1</span>
            <br />
            MAGACINISKI PROSTOR -{" "}
            <span className="red">
              50 m<sup>2</sup>
            </span>
            <br />
            PROMOTERA - <span className="red">50</span>
            <br />
            PROMO LIDERA - <span className="red">5</span>
          </div>
        )}
        {isNoviSad && (
          <div className="hover border novi_sad_hover">
            REGIONALNI MENADŽER - <span className="red">2</span>
            <br />
            MAGACINISKI PROSTOR -{" "}
            <span className="red">
              250 m<sup>2</sup>
            </span>
            <br />
            PROMOTERA - <span className="red">350</span>
            <br />
            PROMO LIDERA - <span className="red">20</span>
          </div>
        )}
        {isKragujevac && (
          <div className="hover border right kragujevac_hover">
            REGIONALNI MENADŽER - <span className="red">2</span>
            <br />
            MAGACINISKI PROSTOR -{" "}
            <span className="red">
              150 m<sup>2</sup>
            </span>
            <br />
            PROMOTERA - <span className="red">300</span>
            <br />
            PROMO LIDERA - <span className="red">10</span>
          </div>
        )}
        {isNis && (
          <div className="hover border right nis_hover">
            REGIONALNI MENADŽER - <span className="red">2</span>
            <br />
            MAGACINISKI PROSTOR -{" "}
            <span className="red">
              150 m<sup>2</sup>
            </span>
            <br />
            PROMOTERA - <span className="red">300</span>
            <br />
            PROMO LIDERA - <span className="red">10</span>
          </div>
        )}
        {isCacak && (
          <div className="hover border cacak_hover">
            REGIONALNI MENADŽER - <span className="red">1</span>
            <br />
            MAGACINISKI PROSTOR -{" "}
            <span className="red">
              50 m<sup>2</sup>
            </span>
            <br />
            PROMOTERA - <span className="red">50</span>
            <br />
            PROMO LIDERA - <span className="red">10</span>
          </div>
        )}
        {isSabac && (
          <div className="hover border sabac_hover">
            REGIONALNI MENADŽER - <span className="red">1</span>
            <br />
            MAGACINISKI PROSTOR -{" "}
            <span className="red">
              50 m<sup>2</sup>
            </span>
            <br />
            PROMOTERA - <span className="red">35</span>
            <br />
            PROMO LIDERA - <span className="red">5</span>
          </div>
        )}
        {isUzice && (
          <div className="hover border uzice_hover">
            REGIONALNI MENADŽER - <span className="red">1</span>
            <br />
            MAGACINISKI PROSTOR - <span className="red">50</span> m<sup>2</sup>
            <br />
            PROMOTERA - <span className="red">30</span>
            <br />
            PROMO LIDERA - <span className="red">3</span>
          </div>
        )}
        {isKraljevo && (
          <div className="hover border right kraljevo_hover">
            REGIONALNI MENADŽER - <span className="red">1</span>
            <br />
            MAGACINISKI PROSTOR -{" "}
            <span className="red">
              50 m<sup>2</sup>
            </span>
            <br />
            PROMOTERA - <span className="red">30</span>
            <br />
            PROMO LIDERA - <span className="red">5</span>
          </div>
        )}
        {isCrnaGora && (
          <div className="hover border crna_gora_hover">
            REGIONALNI MENADŽER - <span className="red">2</span>
            <br />
            MAGACINISKI PROSTOR -{" "}
            <span className="red">
              100 m<sup>2</sup>
            </span>
            <br />
            PROMOTERA - <span className="red">100</span>
            <br />
            PROMO LIDERA - <span className="red">10</span>
          </div>
        )}
        {isMakedonija && (
          <div className="hover border right makedonija_hover">
            REGIONALNI MENADŽER - <span className="red">2</span>
            <br />
            MAGACINISKI PROSTOR -{" "}
            <span className="red">
              250 m<sup>2</sup>
            </span>
            <br />
            PROMOTERA - <span className="red">100</span>
            <br />
            PROMO LIDERA - <span className="red">15</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default PoliplanInNumbers;
