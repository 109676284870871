import React from "react";
import "./../css/Screen1.css";
import Header from "./Header";
// import Slide from "./Slide";
import "./../css/Services.css";
// import MiddlePage1 from "./MiddlePage1";
import HeaderMobile from "./HeaderMobile";
import Strategija from "./services/Strategija";
import KreativniKoncepti from "./services/KreativniKoncepti";
import EventManagment from "./services/EventManagment";
import DrustveneMreze from "./services/DrustveneMreze";
import GrafickiDizajn from "./services/Graficki3dDizajn";
import ProizvodnjaPosIPop from "./services/ProizvodnjaPosIPop";
import ImpresivnePostavke from "./services/ImpresivnePostavke";
import StuffLeasing from "./services/StuffLeasing";
import VideoProdukcija from "./services/VideoProdukcija";
import TradeIHoreca from "./services/TradeIHoreca";

class Services extends React.Component {
  render() {
    return (
      <div>
        <HeaderMobile />
        <div className="services">
          <a href="/">
            <img className="logoScroll" src="/logo.png" alt="logo" />
          </a>
          <Header />
          <Strategija />
          <div className="nothing"></div>
          <KreativniKoncepti />
          <div className="nothing"></div>
          <EventManagment />
          <div className="nothing"></div>
          <GrafickiDizajn />
          <div className="nothing"></div>
          <DrustveneMreze />
          <div className="nothing"></div>
          <ProizvodnjaPosIPop />
          <div className="nothing"></div>
          <ImpresivnePostavke />
          <div className="nothing"></div>
          <StuffLeasing />
          <div className="nothing"></div>
          <VideoProdukcija />
          <div className="nothing"></div>
          <TradeIHoreca />
          <div className="nothing"></div>
        </div>
      </div>
    );
  }
}

export default Services;
