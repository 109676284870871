import React from "react";
import Header from "../Header";
import HeaderMobile from "../HeaderMobile";
import "./../../css/General.css";
import "./../../css/Intervju.css";

class Intervju extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <HeaderMobile />
        <a href="/">
          <img className="logoScroll" src="/logo.png" alt="logo" />
        </a>
        <div className="page">
          <div className="page-left">
            <div className="page-title">
              <span id="title-non-bold">Nikola Vitas</span>
              <br />
              <span>INTERVJU</span>
            </div>
            <div className="page-right-mobile">
              <img
                src="/Intervju/Nikola-01-02.jpg"
                className="page-img"
                alt="allInOne"
              />
            </div>
            <div className="page-text">
              <span className="bold-word">
                1. Šta je posao konceptualnog dizajnera?
              </span>
            </div>
            <div className="page-text">
              Konceptualni dizajner je tu da spoji sve što vam se u prvi mah
              učini nemogućim. Zapravo, konceptualni dizajner je tu da nešto što
              vi ni ne možete da zamislite on vidi i izdizajnira, doda svoj
              talenat i znanje, a nekad i da vam pročita misli. :)
            </div>

            <div className="page-text">
              <span className="bold-word">
                2. Šta je sve potrebno da bi se došlo do komentara <br /> “Vau
                ovo je baš dobro!”?
              </span>
            </div>
            <div className="page-text">
              Sve počinje od look and feel-a kompanije sa kojom sarađujemo,
              upoznajemo se, razgovaramo o očekivanjima i stvaramo prijateljski
              odnos. Sve nakon toga je mašta i predanost momentu. Važno je
              ispoštovati funkcionalne standarde, ali je uvek potrebno pomeriti
              granice mogućeg i realnog. E tada se dešava VAU.
            </div>

            <div className="page-text">
              <span className="bold-word">
                3. S obzirom na to da se trendovi u dizajnu menjaju, <br />
                šta je to što je sada pun pogodak?
              </span>
            </div>
            <div className="page-text">
              Jednostavnost i minimalizam je nešto što se pokazalo najefektnijim
              i najupečatljivijim. Potrebe tržišta se menjaju, <br />a menjaju
              se i potrebe naših partnera u polju dizajna jer njihovi brendovi
              konstantno rastu i razvijaju se. Naravno, uvek se treba osvrnuti
              na trenutna dešavanja u svetu kreative, ali na takav način da kroz
              nove trendove dođete do najboljeg rešenja, a ne slepo ih pratiti.
            </div>

            <div className="page-text">
              <span className="bold-word">4. Odakle crpiš inspiraciju?</span>
            </div>
            <div className="page-text">Iz praznog papira…</div>

            <div className="page-text">
              <span className="bold-word">
                5. Koje su to najčešće greške koje uočavaš u dizajnerskim
                rešenjima danas?
              </span>
            </div>
            <div className="page-text">
              Predizajniranje elemenata i nesklad brenda i potreba. Ovo se češto
              dešava mladjim dizajnerima koji se iz silne želje da sve njihove
              ideje stanu u jedan projekat ne osvrnu na funkcionalnost proizvoda
              i njegovu suštinu.
            </div>

            <div className="page-text">
              <span className="bold-word">
                6. I za kraj, koji savet daješ partnerima sa kojima sarađuješ,{" "}
                <br />a koji bi dao mladom dizajneru početniku?
              </span>
            </div>
            <div className="page-text" style={{ paddingBottom: "100px" }}>
              <span className="red-bold-text">
                “POLAKO LJUDI, stižemo sve!” :)
              </span>
            </div>
            <div className="nothing"></div>
          </div>

          <div className="page-right" id="page-right-intervju">
            <img
              src="/Intervju/Nikola-01-02.jpg"
              className="page-img"
              alt="strategija"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Intervju;
